// Render Prop
import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import ReactLoading from 'react-loading';
import PreQualificationFormInitialStates from '../../laa/PreQualificationFormInitialStates';
import CareCreditFormStates from '../../carecredit/FormStates';
import submitHandler from './SubmitHandler';
import { States, EmploymentStatuses, fieldClasses } from '../../Helpers';
import PhoneFields from '../../PhoneFields';
import Stores from '../../Stores';
import Services from '../../laa/Services';
import ServiceLending from '../../laa/services/ServiceLending';

import { 
  validationSchema
} from './FormValidator';


const axios = require('axios');

function Submitting(props) {
  if (props.isSubmitting) {
    return (
      <ReactLoading type="bubbles" color="black" height={'50%'} width={'50%'} />
    );
  }
  return (<span></span>);
}

class QuickscreenForm extends React.Component {
  constructor(props) {
    super(props);

    let slug = this.props.match.params.slug;
    if(!slug) {
      alert('Missing store slug');
      return;
    }

    let params = new URLSearchParams(this.props.location.search);

    this.initialValues = CareCreditFormStates(params.get('state') || 'default');
    this.initialValues.lender = 'CareCredit';
    this.initialValues.response = params.get('state') || '';
    // const store = Stores.fromSlug(slug);
    axios.defaults.params = axios.defaults.params || {};

    this.state = {
      services: ['hair'],
      loading: true,
      slug,
      store: {},
      errorSummary: null,
      disclosures: {
        email: false,
        mobilePhone: false,
        homePhone: false,
        grossIncome: false
      }
    };
  }

  componentDidMount() {
    Stores.fromSlug(this.state.slug)
      .then((store) => {
        axios.defaults.params.storeName = store.name;
        this.setState({
          store,
          loading: false
        });
        ServiceLending.lenders(store, this.initialValues);
      });
  }

  toggleDisclosure(which) {
    const newDisclosureState =
      Object.assign({}, this.state.disclosures);
    newDisclosureState[which] = !newDisclosureState[which];
    this.setState({disclosures: newDisclosureState});
  }

  render() {
    return (
      <div id="body">
        {this.state.loading && 
          (<ReactLoading type="bubbles" color="black" height={'15%'} width={'15%'} textalign="center" />)
        }
        
        { !this.state.loading && this.state.services.length === 0 &&
          <Services onSubmit={(services) => {
            this.setState({
              services
            })
          }} />
        } { !this.state.loading && this.state.services.length > 0 &&
          <div>
          <h1>Consultation Form</h1>

          <h2>Personal Information</h2>
          <Formik
            initialValues={this.initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting }) => {
              values.storeSlug = this.state.store.slug;
              submitHandler(values, this.state.services, this.state.store, {
                setSubmitting,
                history: this.props.history,
                setErrorSummary: (errorSummary) => {
                  this.setState({ errorSummary });
                }
              });
            }}
          >
            {({ isSubmitting, values, errors, handleBlur, handleChange, touched, setFieldValue }) => (
              <Form>
                <Field type="hidden" name="next"></Field>
                <Field type="hidden" name="response"></Field>
                <div className="rowWrap">
                  <div className="formRow">
                    <div style={{width: '40%'}}>
                      <label htmlFor="firstName">First Name*</label>
                      <Field name="firstName" id="firstName" type="text"
                        className={fieldClasses([], touched.firstName && errors.firstName)} />
                      <div className="error">
                        <ErrorMessage name="firstName" component="span" />
                      </div>
                    </div>
                    <div style={{width: '20%'}}>
                      <label htmlFor="middleInitial">Middle Initial</label>
                      <Field name="middleInitial" id="middleInitial" type="text" maxLength="1"/>
                    </div>
                    <div style={{width: '40%'}}>
                      <label htmlFor="lastName">Last Name*</label>
                      <Field name="lastName" id="lastName" type="text"
                        className={fieldClasses(["fs-block"], touched.lastName && errors.lastName)} />
                      <div className="error">
                        <ErrorMessage name="lastName" component="span" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="rowWrap">
                  <div className="formRow">
                    <div style={{width: '50%'}}>
                      <label htmlFor="streetAddressLine1">Address*</label>
                      <Field name="streetAddressLine1" id="streetAddressLine1" type="text"
                        className={fieldClasses(["fs-block"], touched.streetAddressLine1 && errors.streetAddressLine1)} />
                      <div className="error">
                        <ErrorMessage name="streetAddressLine1" component="span" />
                      </div>
                    </div>
                    <div style={{width: '50%'}}>
                      <label htmlFor="streetAddressLine2">Unit / APT Number</label>
                      <Field name="streetAddressLine2" id="streetAddressLine2" type="text" />
                    </div>
                  </div>
                </div>
                <div className="rowWrap">
                  <div className="formRow">
                    <div style={{width: '49%'}}>
                      <label htmlFor="city">City*</label>
                      <Field name="city" id="city" type="text"
                        className={fieldClasses([], touched.city && errors.city)} />
                      <div className="error">
                        <ErrorMessage name="city" component="span" />
                      </div>
                    </div>
                    <div  style={{width: '20%'}}>
                      <label htmlFor="state">State*</label>

                      <Field name="state" id="state" component="select" placeholder="Choose A State"
                        className={fieldClasses([], touched.state && errors.state)}>
                        {Object.keys(States).map((key) =>
                          (<option key={key} value={key}>{key}</option>)
                        )}
                      </Field>
                      <div className="error">
                        <ErrorMessage name="state" component="span" />
                      </div>
                    </div>
                    <div style={{width: '30%'}}>
                      <label htmlFor="zip">Zip*</label>
                      <Field name="zip" id="zip" type="text" maxLength="5"
                        className={fieldClasses([], touched.zip && errors.zip)} />
                      <div className="error">
                        <ErrorMessage name="zip" component="span" />
                      </div>
                    </div>
                  </div>
                </div>
                
                <div className="rowWrap">
                  <div className="formRow">
                    <PhoneFields phoneType="home"
                      label="Phone"
                      toggleDisclosure={this.toggleDisclosure.bind(this)}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      required={true}
                      touched={touched}
                      errors={errors}
                      width='50%'/>
                  </div>
                </div>                
                <div style={{margin: '10px 0 200px 0', float: 'right', textAlign: 'right'}}>
                  <button type="submit"
                    disabled={isSubmitting}
                    id="Next"
                    className={isSubmitting ? 'disabled' : ''}>
                    Next
                  </button>
                  { Object.keys(errors).length > 0 && (
                    <div className="error" style={{position: 'relative', textAlign: 'right'}}>Please check your inputs.</div>
                  )}
                  {this.state.errorSummary && (
                    <div className="error" style={{position: 'relative', textAlign: 'right'}}>{this.state.errorSummary}</div>
                  )}
                  <Submitting isSubmitting={isSubmitting} />
                </div>
                <div style={{clear: 'both'}}></div>
              </Form>
            )}
          </Formik>
        </div>
      }
      </div>
    );
  }
};

export default QuickscreenForm;

