import React from 'react';
import { Link } from "react-router-dom";

const Footer = () => (
	<div className="footer">
		<div>
			<h1>
				<Link to="/"><img alt="LaserAway" width="200px" src="/LaserAway-TypeLogo-RGB.png"/></Link>
			</h1>
		</div>
	</div>
);

export default Footer;