import React from 'react';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
const moment = require('moment');
const axios = require('axios');

const columns = [{
  dataField: 'name',
  text: 'Name'
}, {
  dataField: 'status',
  text: 'App Status'
}, {
  dataField: 'requestAmount',
  text: 'Request Amount'
}, {
  dataField: 'approvedAmount',
  text: 'Approved Amount'
}, {
  dataField: 'lender',
  text: 'Lender'
},{
  dataField: 'store',
  text: 'Store Name'
}, {
  dataField: 'publishTime',
  text: 'Time',
  formatter: dateFormatter
},
{
  dataField: 'offerPresented',
  text: 'Was Offered Presented?'
}
];


function dateFormatter(cell) {
    return `${moment(cell).format("dddd MMM DD YYYY @ HH:MM:SS")} GMT`;
}

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        apps: [],
        token: null,
        tokenSet: false
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    const token = new URLSearchParams(this.props.location.search).get('token');
    this.setState({
      token,
      tokenSet: token ? true : false
    });
    this.fetchApps(token);
  };

  fetchApps(token) {
    if(this.state.token || token){
      const appsApiUrl = 
              `${process.env.REACT_APP_SERVICE_LENDING_URL}/v1/apps?env=production`;
          axios.get(appsApiUrl, {
              headers: {
                Authorization: this.state.token || token
              }
            })
              .then((response) => {
                  this.setState({apps: response.data});
              })
              .catch(e => console.log(e.response.data));
    }
  };

  componentDidMount() {
    if(this.state.tokenSet){
      this.fetchApps();
    }
  };

  handleChange(event) {
    this.setState({token: event.target.value});
  };

  handleSubmit(event) {
    this.setState({tokenSet: true});
    this.fetchApps();
    event.preventDefault();
  };

  render() {
    return (<div id="body">
      {this.state.apps.length > 0 &&
        <BootstrapTable
          keyField='id'
          data={ this.state.apps }
          columns={ columns } />
      }
      {this.state.apps.length === 0 &&
        <form onSubmit={this.handleSubmit}>
        <label>
          Enter your token:
          <input type="text" value={this.state.token} onChange={this.handleChange} />
        </label>
        <input type="submit" value="Submit" />
      </form>
      }
    </div>);
  };
};

export default Index;
  