const Yup = require('yup');
const moment = require('moment');

const validationShape = {
  firstName: Yup.string()
    .required('Required Field'),
  middleInitial: Yup.string()
    .notRequired()
    .nullable(),
  lastName: Yup.string()
    .required('Required Field'),
  streetAddressLine1: Yup.string()
    .matches(/^(?!p\.o\.?).*/i, 'P.O. boxes not allowed')
    .required('Required Field'),
  streetAddressLine2: Yup.string()
    .notRequired()
    .nullable(),
  city: Yup.string()
    .required('Required Field'),
  state: Yup.string()
    .required('Required Field'),
  zip: Yup.string()
    .required('Required Field')
    .matches(/\d{5}/, '5 digits'),
  homePhoneArea: Yup.string()
    .required('Required Field')
    .matches(/\d{3}/, '3 digits'),
  homePhonePrefix: Yup.string()
    .required('Required Field')
    .matches(/\d{3}/, '3 digits'),
  homePhoneLine: Yup.string()
    .required('Required Field')
    .matches(/\d{4}/, '4 digits')
};

module.exports = {
  validationShape,
  validationSchema: Yup.object().shape(validationShape)
};