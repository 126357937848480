import React from 'react';

class Declined extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            slug: this.props.match.params.slug
        };
    };
    copyToClipboard() {
        var copyText = document.getElementById("purl");

        // Select the text field
        copyText.select();
        copyText.setSelectionRange(0, 99999); // For mobile devices

        // Copy the text inside the text field
        window.navigator.clipboard.writeText(copyText.value)
            .then(async () => {
                // Alert the copied text
                console.log('copied', await navigator.clipboard.readText());
            });
    };
    render(){
        let params = new URLSearchParams(this.props.location.search);
        return (
            <div id="body" className="status">
                <h2>
                    <img width="25px" src="/carecredit/triangle_icon.png" />
                	Please proceed to the LaserAway Advantage Waterfall
                </h2>
                <input type="text" id="purl" 
                    value={decodeURIComponent(`https://lending.laseraway.net/app/${this.state.slug}`)}
                    onFocus={this.copyToClipboard} readOnly/> <br/> 
                <button onClick={this.copyToClipboard}>Copy link</button>
            </div>
        );    
    }
};
export default Declined;
