import React from 'react';

class DownTime extends React.Component {
    render () {
        return (<div id="body" className="status">
            <h1>LAA is down for maintenance.</h1>
            <h2>Don't worry! We'll be back soon.</h2>
        </div>);
    }
}

export default DownTime;