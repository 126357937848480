import React from 'react';

class AccountFound extends React.Component {
    constructor(props) {
        super(props);
        const params = new URLSearchParams(props.location.search);
        const amount = params.get('amount').split('.')[0];
        const redirect = params.get('redirect');
        
        this.state = {
            amount,
            redirect,
            slug: this.props.match.params.slug
        };
    };

    copyToClipboard(id) {
        var copyText = document.getElementById(id);

        // Select the text field
        copyText.select();
        copyText.setSelectionRange(0, 99999); // For mobile devices

        // Copy the text inside the text field
        window.navigator.clipboard.writeText(copyText.value)
            .then(async () => {
                // Alert the copied text
                console.log('copied', await navigator.clipboard.readText());
            });
    };

    render(){        
        return (
            <div id="body" className="status">
                <h2>
                    <img width="25px" src="/carecredit/square_icon.png" />
                    The customer has an existing CareCredit credit card.  Available credit is ${this.state.amount}.
                    Send below link and tell them to click the "Pay Now button under Pay"
                </h2>
                <input type="text" id="purl" 
                    value={decodeURIComponent(this.state.redirect)}
                    onFocus={() => this.copyToClipboard('purl')} readOnly/> <br/> 
                <button onClick={() => this.copyToClipboard('purl')}>Copy link</button>

                <h6>Or send the customer to the LAA waterfall</h6>
                <input type="text" id="laa-waterfall" 
                    value={decodeURIComponent(`https://lending.laseraway.net/app/${this.state.slug}`)}
                    onFocus={() => this.copyToClipboard('laa-waterfall')} readOnly/> <br/> 
                <button onClick={() => this.copyToClipboard('laa-waterfall')}>Copy link</button>
            </div>
        );    
    }
};
export default AccountFound;
