import React from 'react';

class Services extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			services: []
		};
	};

	toggleSelected(e) {
		const index = this.state.services.indexOf(arguments[0]);
		if(index > -1){
			this.state.services.splice(index, 1);
			this.setState({
				services: this.state.services
			});
		} else {
			this.state.services.push(arguments[0]);
			this.setState({
				services: this.state.services
			});
		}
	}

	dotClass(which) {
		const index = this.state.services.indexOf(which);
		if(index > -1){
			return 'dot selected';
		} else {
			return 'dot';
		}
	}

	render() {
		return (
			<div className="status">
				<h1>Which treatment are you getting?</h1>

				<h2>Select all that apply</h2>
				<br/>
				<div className={this.dotClass('hair')} onClick={this.toggleSelected.bind(this, 'hair')}>
					<span>
						<img alt="Laser Hair Removal" src="/aestheticservices-laserhairremoval.png"/>
					</span>
					<h3>Laser Hair Removal</h3>
				</div>
				<div className={this.dotClass('tattoo')} onClick={this.toggleSelected.bind(this, 'tattoo')}>
					<span>
						<img alt="Laser Tattoo Removal" src="/aestheticservices-lasertattooremoval.png"/>
					</span>
					<h3>Laser Tattoo Removal</h3>
				</div>
				<div className={this.dotClass('clearandbrilliant')} onClick={this.toggleSelected.bind(this, 'clearandbrilliant')}>
					<span>
						<img alt="Clear and Brilliant" src="/aestheticservices-clear+brilliant.png"/>
					</span>
					<h3>Clear and Brilliant</h3>
				</div>
				<div className={this.dotClass('ipl')} onClick={this.toggleSelected.bind(this, 'ipl')}>
					<span>
						<img alt="IPL" src="/ipl.png"/>
					</span>
					<h3>IPL</h3>
				</div>
				<div className={this.dotClass('none')} onClick={this.toggleSelected.bind(this, 'none')}>
					<span>
						<img alt="Not Listed" src="/not-listed.png"/>
					</span>
					<h3>Not Listed</h3>
				</div>
				<br/>
				<br/>
				<button 
					id="nextButton" 
					className="button"
					onClick={(e) => {this.props.onSubmit(this.state.services)}}
					style={{float: 'none', marginTop: '20px'}}>Next</button>
			</div>
		);
	}

}
export default Services;