import React from 'react';
import { Field, ErrorMessage } from 'formik';
import { Months, daysInMonth, dobYears, fieldClasses } from './Helpers';

const BirthdayFields = (props) => {
    const values = props.values;
    const initialYear = props.initialYear || new Date().getFullYear();
    return (<div style={{width: props.width, flexDirection: 'row', display: 'flex', marginRight: '0'}}>
      <div style={{width: "calc(100% / 3)"}}>
        <label htmlFor="dobMonth">Date of Birth*</label>
        <Field name="dobMonth" component="select" id="dobMonth"
          className={fieldClasses([], props.touched.dobMonth && props.errors.dobMonth)}
        >
          { Months.map((month, index) => month !== '' ? 
            (<option key={month} value={index}>{month}</option>) : 
            (<option key='dobMonthPlaceholder'></option>)) }
        </Field>
        <div className="error">
          <ErrorMessage name="dobMonth" component="span" />
        </div>
      </div>
      <div style={{width: "calc(100% / 3)"}}>
        <label>&nbsp;</label>
        <Field name="dobDay" component="select" id="dobDay"
          className={fieldClasses([], props.touched.dobDay && props.errors.dobDay)}
        >
          { daysInMonth(values.dobMonth, values.dobYear).map((index) => 
            (<option key={index} value={index}>{index}</option>)
          )}
        </Field>
        <div className="error">
          <ErrorMessage name="dobDay" component="span" />
        </div>
      </div>
      <div style={{width: "calc(100% / 3)"}}>
        <label>&nbsp;</label>
        <Field name="dobYear" component="select" id="dobYear"
          className={fieldClasses([], props.touched.dobYear && props.errors.dobYear)}
        >
          { dobYears(initialYear).map((year) =>
            (<option key={year} value={year}>{year}</option>)
          )}
        </Field>
        <div className="error">
          <ErrorMessage name="dobYear" component="span" />
        </div>
      </div>
    </div>
  );
};

export default BirthdayFields;