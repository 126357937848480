const GenericServiceLending = require('../ServiceLending');
const lender = 'Ally';
const Store = require('../../../Stores');

const ServiceLending = {
    lender,
    eligible: (services, store, formState = {}) => {
      if(formState.lender) {
        if(formState.lender !== lender) {
          console.log(`form configured for ${formState.lender}, ${lender} is ineligible`);
          return false;
        } else {
          console.log(`form configured for ${formState.lender}, ${lender} is eligible.`);
          return true;
        }
      }
      console.log(`${lender} is eligible: ${!!store.epfId}`);
      return !!store.epfId;
    },
    createApp: (formState, store) => {
      const values = GenericServiceLending.formStateAdapter(formState, store);
      return GenericServiceLending.wrapResponse(GenericServiceLending.createCreditApplication(values, formState.requestAmount), lender);
    }
};

module.exports = ServiceLending;