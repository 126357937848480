const FormStates = (namedState) => {
  return {
    default:  {
      firstName: "",
      middleInitial: "",
      lastName: "",
      streetAddressLine1: "",
      streetAddressLine2: "",
      city: "",
      state: "",
      zip: "",
      homePhoneArea: "",
      homePhoneLine: "",
      homePhonePrefix: ""
    },
    declined: {
      city: "Colchester",
      email: "test3@test.com",
      firstName: "Martin",
      // grossIncome: 100000,
      homePhoneArea: "213",
      homePhoneLine: "7854",
      homePhonePrefix: "654",
      lastName: "Jaobson",
      middleInitial: "",
      state: "CT",
      streetAddressLine1: "157 Cjestnut Hill",
      zip: "06415",
      response: 'declined'
      // mobilePhoneArea: "555",
      // mobilePhoneLine: "6543",
      // mobilePhonePrefix: "987",
      // dobMonth: 3,
      // dobDay: 10,
      // dobYear: 1983,
      // ssnArea: "666",
      // ssnGroup: "50",
      // ssnSerial: "8877",
      // employmentStatus: "Employed",
      // employerName: "LaserAway",
      // employmentDate: "12/01/2000",
      // employmentPosition: "CTO",
      // employerPhoneArea: "555",
      // employerPhonePrefix: "555",
      // employerPhoneLine: "5555",
      // rentOrOwn: "Own",
      // mortgageLeaseAmount: 5000,
      // requestAmount: 1500
    },
    accountFound: {
      city: "Allen",
      email: "test@test.com",
      firstName: "Shannon",
      lastName: "Kennedy",
      middleInitial: "",
      state: "TX",
      streetAddressLine1: "703 Victorian",
      zip: "75002",
      homePhoneArea: "213",
      homePhoneLine: "7854",
      homePhonePrefix: "654",
      response: 'accountFound'
      // grossIncome: 100000,
      // mobilePhoneArea: "555",
      // mobilePhoneLine: "6543",
      // mobilePhonePrefix: "987",
      // dobMonth: 3,
      // dobDay: 10,
      // dobYear: 1983,
      // ssnArea: "666",
      // ssnGroup: "22",
      // ssnSerial: "3872",
      // employmentStatus: "Employed",
      // employerName: "LaserAway",
      // employmentDate: "12/01/2000",
      // employmentPosition: "CTO",
      // employerPhoneArea: "555",
      // employerPhonePrefix: "555",
      // employerPhoneLine: "5555",
      // rentOrOwn: "Own",
      // mortgageLeaseAmount: 5000,
      // requestAmount: 1500
    },
    approved: {
      city: "Antelope",
      email: "test2@test.com",
      firstName: "Teresa",
      lastName: "Abel",
      middleInitial: "E",
      state: "CA",
      streetAddressLine1: "4744 Porto Pino",
      zip: "95843",
      homePhoneArea: "213",
      homePhoneLine: "7854",
      homePhonePrefix: "654",
      response: 'approved'
      // grossIncome: 100000,
      // mobilePhoneArea: "555",
      // mobilePhoneLine: "6543",
      // mobilePhonePrefix: "987",
      // dobMonth: 3,
      // dobDay: 10,
      // dobYear: 1983,
      // ssnArea: "666",
      // ssnGroup: "11",
      // ssnSerial: "4757",
      // employmentStatus: "Employed",
      // employerName: "LaserAway",
      // employmentDate: "12/01/2000",
      // employmentPosition: "CTO",
      // employerPhoneArea: "555",
      // employerPhonePrefix: "555",
      // employerPhoneLine: "5555",
      // rentOrOwn: "Own",
      // mortgageLeaseAmount: 5000,
      // requestAmount: 1500
    }
  }[namedState];
};

module.exports = FormStates;
