import 'url-search-params-polyfill';
import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import FullStory from 'react-fullstory';
import Header from './Header';
import Footer from './Footer';
import ProgressBar from './ProgressBar';
import Declined from './Declined';
import StoreList from './StoreList';
import DownTime from './DownTime';
import cookie from 'react-cookies';
import LAAPreQualificationForm from './laa/PreQualificationForm';
import LAAAuthorized from './laa/Authorized';
import Admin from './admin/index';
import QuickScreenForm from './admin/carecredit/QuickScreenForm';
import QuickScreenAuthorized from './admin/carecredit/Authorized';
import QuickScreenDeclined from './admin/carecredit/Declined';
import QuickScreenAccountFound from './admin/carecredit/AccountFound';
import QuickScreenStoreList from './admin/carecredit/StoreList';
import './App.css';

const axios = require('axios');
window['_fs_ready'] = () => {
  axios.defaults.params = axios.defaults.params || {};
  axios.defaults.params.fsSessionUrl = window.FS.getCurrentSessionURL();
  let fsSessionId = cookie.load('fsSessionId');
  if(!fsSessionId) {
    fsSessionId = window.FS.getCurrentSession();
    cookie.save('fsSessionId', fsSessionId, {
      path: '/'
    });
  }
};

class App extends Component {
  render() {
    return (
      <Router>
        <div id="container">

          <FullStory org="JXHBN" />
          <Header />
          <ProgressBar />
          <Switch>
            <Route path="/downtime" exact component={DownTime} />
            <Route path="/" exact component={StoreList} />
            <Route path="/declined/" component={Declined} />
            <Route path="/laa/authorized" component={LAAAuthorized} />
            <Route path="/app/:slug" component={LAAPreQualificationForm} />
            <Route path="/admin/carecredit/quickscreen/index" component={QuickScreenStoreList} />
            <Route path="/admin/carecredit/quickscreen/:slug/declined" component={QuickScreenDeclined} />
            <Route path="/admin/carecredit/quickscreen/:slug/approved" component={QuickScreenAuthorized} />
            <Route path="/admin/carecredit/quickscreen/:slug/accountFound" component={QuickScreenAccountFound} />
            <Route path="/admin/carecredit/quickscreen/:slug" component={QuickScreenForm} />
            <Route path="/admin" component={Admin} />

          </Switch>
          <Footer />
        </div>
      </Router>
    );
  }
}

export default App;
