// The keys are GreenSky location ids, the values are other important properties.
const axios = require('axios');

let allStores = [];
let theStore = {};
const serviceHost = process.env.REACT_APP_STORE_CONFIG_HOST;
const Stores = {
    stores: async () => {
        if(allStores.length === 0) {
            await Stores.fetchAll();
        } 
        return allStores;
    },
    fetchAll: async () => {
        const response = 
            await axios.get(`${serviceHost}/v1/stores`);
        allStores = allStores.concat(response.data[0].map((store) => {
            return Stores.format(store);
        }));
    },
    fetchStore: async (slug) => {
        const response = 
            await axios.get(`${serviceHost}/v1/stores/${slug}`);
        theStore = Stores.format(response.data[0]);
    },
    firstLookUrl: (storeKey) => {
        return `/app/${Stores[storeKey].slug}`;
    },
    fromSlug: async (slug) => {
        if(theStore.slug === slug) {
            return theStore;
        }
        await Stores.fetchStore(slug);
        return theStore;
    },
    format: (store) => {
        return {
            gsInstallmentId: store.GreenSkyInstallmentId,
            slug: store.Slug,
            name: store.Name,
            ugaLocationId: store.UGALocationId,
            varidiMerchantId: store.VaridiMerchantId,
            careCreditMerchantId: store.CareCreditMerchantId,
            careCreditClientId: store.CareCreditClientId,
            epfId: parseInt(store.EnhancePatientFinanceId)
        };
    }
};
window.Stores = Stores;
export default Stores;