import React from 'react';
import { Field, ErrorMessage } from 'formik';
import { fieldClasses } from './Helpers';
const AnnualIncome = (props) => {
  return (<div style={{width: '50%'}}>
      <label htmlFor="grossIncome" id="grossIncomeLabel">Gross Annual Income*</label>
      <Field name="grossIncome"
        id="grossIncome"
        type="tel"
        className={fieldClasses([], props.touched.grossIncome && props.errors.grossIncome)}
        onChange={(event) => {
          if(!event.target.value) {
            props.setFieldValue('grossIncome', '');
            return;
          }
          const val = event.target.value.toString().replace(/\D/, '');
          props.setFieldValue('grossIncome', parseInt(val).toLocaleString());
        }}
        onFocus={() => { props.toggleDisclosure('grossIncome') }}
        onBlur={(e) => { props.handleBlur(e); props.toggleDisclosure('grossIncome') }}/>
      <div className="error">
        <ErrorMessage name="grossIncome" component="span" />
      </div>
    </div>);
};

export default AnnualIncome;
