import React from 'react';

const Declined = (props) => {
	const params = new URLSearchParams(props.location.search);
	return (<div id="body" className="status">
		<img alt="declined" src="/declined-icon.svg" width="25%" style={{marginTop: '30px'}} />
		<h1>Approval Decision</h1>
		<h2>Unfortunately, we are unable to approve your application at this time.
		You will receive a letter within 30 days with the results of the credit review.</h2>
		{params.get('n') && (<div>
			<br/>
			<br/>
			<h2>But don't worry, we've got options!</h2>
			<a className="button" href={params.get('n')} style={{float: 'none', marginTop: '20px'}}>See More Options</a>
		</div>)}
		
	</div>);
};

export default Declined;