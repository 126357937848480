const axios = require('axios');

const headers = {
  'Content-Type': 'application/json'
};
const lender = 'UGA';
const ServiceLending = {
    lender,
    eligible: (services, store, formState) => {
      if(formState.lender) {
        if(formState.lender !== lender) {
          console.log(`form configured for ${formState.lender}, ${lender} is ineligible`);
          return false;
        } else {
          console.log(`form configured for ${formState.lender}, ${lender} is eligible.`);
          return true;
        }
      }
      console.log(`${lender} is eligible: ${!!store.ugaLocationId}`);
      return !!store.ugaLocationId;
    },
    wrapResponse: (promise) => {
      return promise.then((result) => {
        const resultWrapper = {
          status: "0",
          message: 'Initialized',
          redirectUrl: null,
          amount: -1,
          originalResult: result,
          lender: 'UGA'
        };
        if(result.data.decision !== 'Declined') {
          resultWrapper.status = '1';
        }
        resultWrapper.reEntryUrl = result.data.client_url;
        delete resultWrapper.redirectUrl;
        resultWrapper.amount = result.data.credit_line;
        return resultWrapper;
      }).catch((e) => {
        return Promise.reject({
          lender,
          error: e
        });
      });
    },
    createApp: (formState, store, services) => {
      formState.qualifiedForServicing = !(services.length === 1 && services[0] === 'none');
      const values = ServiceLending.formStateAdapter(formState, store);
      values.location_id = store.ugaLocationId;
      return ServiceLending.wrapResponse(ServiceLending.createCreditApplication(values));
    },
    createCreditApplication: (values) => {
        const creditApplicationApiUrl = 
            `${process.env.REACT_APP_SERVICE_LENDING_URL}/v1/uga/creditApplication`;
        return axios.post(creditApplicationApiUrl, values, { headers });
    },
    formStateAdapter: (formState, store) => {
      const fieldMapping = {
        firstName: "first_name",
        lastName: "last_name",
        middleInitial: "middle_initial",
        streetAddressLine1: "street",
        streetAddressLine2: "street2",
        employmentStatus: "employment_status",
        employerName: "employer_name",
        employmentDate: "employment_date",
        employmentPosition: "employment_position",
        mortgageLeaseAmount: "mortgage_lease_amount",
        rentOrOwn: "rent_or_own",
        requestAmount: "cash_price"
      };
      const adapted = Object.assign({}, formState);
      adapted.country = 'United States';
      adapted.date_of_birth = `${adapted.dobMonth.toString().padStart(2, '0')}/${adapted.dobDay.toString().padStart(2, '0')}/${adapted.dobYear}`;
      adapted.ssn = `${adapted.ssnArea}-${adapted.ssnGroup}-${adapted.ssnSerial}`;
      adapted.phone_primary_number = `(${adapted.mobilePhoneArea})${adapted.mobilePhonePrefix}-${adapted.mobilePhoneLine}`;
      adapted.phone_primary_type = 'Mobile';
      
      adapted.phone_secondary_number = `(${adapted.homePhoneArea})${adapted.homePhonePrefix}-${adapted.homePhoneLine}`;
      adapted.phone_secondary_type = 'Home';
      if(!adapted.phone_secondary_number.match(/\(\d{3}\)\d{3}-\d{4}/ig) || 
        adapted.phone_primary_number === adapted.phone_secondary_number) {
        adapted.phone_secondary_number = '';
        adapted.phone_secondary_type = '';
      }

      adapted.phone_work_number = `(${adapted.employerPhoneArea})${adapted.employerPhonePrefix}-${adapted.employerPhoneLine}`;
      if(!adapted.phone_work_number.match(/\(\d{3}\)\d{3}-\d{4}/ig)) {
        delete adapted.phone_work_number;
      }

      adapted.qualified_for_servicing = adapted.qualifiedForServicing || false;

      Object.keys(fieldMapping).forEach((key) => {
        adapted[fieldMapping[key]] = adapted[key];
        delete adapted[key];
      });

      Object.keys(adapted).forEach((key) => {
        if(adapted[key] === '' || adapted[key] === undefined) {
          delete adapted[key];
        }
      });

      //set the monthly income
      adapted.monthly_income = parseInt(parseInt(adapted.grossIncome.toString().replace(/\D/g, '')) / 12);
      adapted.cash_price = 0.00; //parseInt(adapted.cash_price.toString().replace(/\D/g, ''));
      adapted.down_payment = 0.00;

      if(adapted.mortgage_lease_amount > 99999) {
        adapted.mortgage_lease_amount = 99999;
      }

      adapted.last_name = adapted.last_name.replace(/\s+|[0-9]|\W/ig, '');
      if(adapted.middle_initial && adapted.middle_initial.length > 1) {
        adapted.middle_initial = adapted.middle_initial.substring(0,1);
      }
      delete adapted.grossIncome;
      delete adapted.mobilePhoneArea;
      delete adapted.mobilePhonePrefix;
      delete adapted.mobilePhoneLine;
      delete adapted.homePhoneArea;
      delete adapted.homePhonePrefix;
      delete adapted.homePhoneLine;
      delete adapted.employerPhoneArea;
      delete adapted.employerPhonePrefix;
      delete adapted.employerPhoneLine;
      delete adapted.dobMonth;
      delete adapted.dobDay;
      delete adapted.dobYear;
      delete adapted.ssnArea;
      delete adapted.ssnGroup;
      delete adapted.ssnSerial;
      delete adapted.agreed;
      delete adapted.requestAmount;
      delete adapted.qualifiedForServicing;
      delete adapted.lender;
      delete adapted.response;
      delete adapted.storeSlug;
      delete adapted.directMerchantID;
      // console.log('adapted', adapted);
      return adapted;
    }
};

module.exports = ServiceLending;