import ServiceLending from './services/ServiceLending';

const submitHandler = async (formState, services, store, { setSubmitting, history, setErrorSummary }) => {
  try {
    setSubmitting(true);
    console.log(services);
    const offer = await ServiceLending.createCreditApplication(formState, services, store);
    let nextUrl = `/declined`;
    if(offer && offer.status === '1') {
      nextUrl = `/laa/authorized?amount=${offer.amount}&lender=${offer.lender}`;
      if(offer.redirectUrl) {
        nextUrl = `${nextUrl}&redirect=${encodeURI(offer.redirectUrl)}`;
        if(offer.disclosures) {
          const encodedDisclosures = btoa(JSON.stringify(offer.disclosures));
          nextUrl = `${nextUrl}&disclosures=${encodeURIComponent(encodedDisclosures)}`;
        }
      }
    }
    
    setTimeout(() => {
      setSubmitting(false);
      history.push(nextUrl);
    }, 2000);
  } catch (e) {
      if(e.response) {
        setTimeout(() => {
          setSubmitting(false);
          let errorSummary = `${e.response.data.statusCode}`;
          if(e.response.data.message) {
            errorSummary = `${errorSummary} ${e.response.data.message}`;
          }
          setErrorSummary(errorSummary);
        }, 2000);
      } else {
        console.log(e);
        setSubmitting(false);
        setErrorSummary('Something went wrong. Please contact support.');
      }
  }
}

export default submitHandler;