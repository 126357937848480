const axios = require('axios');

const headers = {
  'Content-Type': 'application/json'
};

const ServiceNotifications = {
	create: (values) => {
        const notificationsApiUrl = 
            `${process.env.REACT_APP_SERVICE_LENDING_URL}/v1/notifications`;
        return axios.post(notificationsApiUrl, values, { headers }).catch(e => console.log(e.response.data));
	}
};

export default ServiceNotifications;
