const PreQualificationFormInitialStates = (namedState) => {
  const testData = {
    city: "Cambridge",
    email: "test@laseraway.net",
    firstName: "Alyssa",
    grossIncome: 100000,
    homePhoneArea: "555",
    homePhoneLine: "4567",
    homePhonePrefix: "123",
    lastName: "Hacker",
    middleInitial: "P.",
    mobilePhoneArea: "555",
    mobilePhoneLine: "6543",
    mobilePhonePrefix: "987",
    state: "MA",
    streetAddressLine1: "99 Massachusettes Ave",
    zip: "02139",
    dobMonth: 3,
    dobDay: 10,
    dobYear: 1983,
    ssnArea: "000",
    ssnGroup: "00",
    ssnSerial: "0001",
    employmentStatus: "Employed",
    employerName: "LaserAway",
    employmentDate: "12/01/2000",
    employmentPosition: "CTO",
    employerPhoneArea: "555",
    employerPhonePrefix: "555",
    employerPhoneLine: "5555",
    rentOrOwn: "Own",
    mortgageLeaseAmount: 5000,
    requestAmount: 5000
  };
  if(namedState == 'default') {
    const defaultInput = {};
    Object.keys(testData).reduce((memo, key) => {
      memo[key] = '';
      return memo;
    }, defaultInput);
    return defaultInput;
  }
  return testData;
};

module.exports = PreQualificationFormInitialStates;