const Months = ['', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
const States = {
    "": "Choose A State",
    "AL": "Alabama",
    "AK": "Alaska",
    "AS": "American Samoa",
    "AZ": "Arizona",
    "AR": "Arkansas",
    "CA": "California",
    "CO": "Colorado",
    "CT": "Connecticut",
    "DE": "Delaware",
    "DC": "District Of Columbia",
    "FM": "Federated States Of Micronesia",
    "FL": "Florida",
    "GA": "Georgia",
    "GU": "Guam",
    "HI": "Hawaii",
    "ID": "Idaho",
    "IL": "Illinois",
    "IN": "Indiana",
    "IA": "Iowa",
    "KS": "Kansas",
    "KY": "Kentucky",
    "LA": "Louisiana",
    "ME": "Maine",
    "MH": "Marshall Islands",
    "MD": "Maryland",
    "MA": "Massachusetts",
    "MI": "Michigan",
    "MN": "Minnesota",
    "MS": "Mississippi",
    "MO": "Missouri",
    "MT": "Montana",
    "NE": "Nebraska",
    "NV": "Nevada",
    "NH": "New Hampshire",
    "NJ": "New Jersey",
    "NM": "New Mexico",
    "NY": "New York",
    "NC": "North Carolina",
    "ND": "North Dakota",
    "MP": "Northern Mariana Islands",
    "OH": "Ohio",
    "OK": "Oklahoma",
    "OR": "Oregon",
    "PW": "Palau",
    "PA": "Pennsylvania",
    "PR": "Puerto Rico",
    "RI": "Rhode Island",
    "SC": "South Carolina",
    "SD": "South Dakota",
    "TN": "Tennessee",
    "TX": "Texas",
    "UT": "Utah",
    "VT": "Vermont",
    "VI": "Virgin Islands",
    "VA": "Virginia",
    "WA": "Washington",
    "WV": "West Virginia",
    "WI": "Wisconsin",
    "WY": "Wyoming"
};
const daysInMonth = (month, year) => {
  let count = 31;
  if (month) {
    month = parseInt(month);
    if ([1,3,5,7,8,10,12].indexOf(month) === -1) count = 30
    if (month === 2) {
      count = 28;
      if (year) {
        year = parseInt(year);
        if (isLeapYear(year)) count = 29;
      }
    }
  }
  return [''].concat(Array.from(Array(count).keys()).map((i) => i + 1));
};

const isLeapYear = (year) => {
    return year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0);
}

const dobYears = (currentYear) => {
  return [''].concat(Array.from(Array(100).keys()).map((i) => (currentYear - i)));
};

function fieldClasses(classes, isError) {
  isError && classes.push('error');
  return classes.join(' ');
}

const EmploymentStatuses = ['', 'Employed', 'Homemaker', 'Retired', 
    'Self Employed', 'Social Security/Disability', 'Unemployed'];

module.exports = {
  Months,
  daysInMonth,
  dobYears,
  States,
  EmploymentStatuses,
  isLeapYear,
  fieldClasses
}
