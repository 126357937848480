// Render Prop
import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import ReactLoading from 'react-loading';
import PreQualificationFormInitialStates from './PreQualificationFormInitialStates';
import submitHandler from './SubmitHandler';
import { States, EmploymentStatuses, fieldClasses } from '../Helpers';
import PhoneFields from '../PhoneFields';
import BirthdayFields from '../BirthdayFields';
import Stores from '../Stores';
import AnnualIncome from '../AnnualIncome';
import Services from './Services';
import ServiceLending from './services/ServiceLending';

import { 
  validationSchema
} from './FormValidator';


const axios = require('axios');
const fieldDisclosures = {
  homePhone: 'If you do not have a mobile number then enter an alternative number. If you provide a mobile phone number, you authorize any financial institution that makes a GreenSky®  loan to you and its agents, contractors, successors, assigns, or service providers to contact you at this mobile number, and any future number that you provider for your cellular telephone or other wireless device, by phone or text message using automated telephone dialing system or artificial or prerecorded voice messages, for any legal purpose, including, but not limited to, servicing a loan. You may revoke your consent at any time contacting us at 1-866-936-0602 or P.O. Box 29429, Atlanta, GA 30359.',
  mobilePhone: 'If you provide a mobile phone number, you authorize any financial institution that makes a GreenSky®  loan to you and its agents, contractors, successors, assigns, or service providers to contact you at this mobile number, and any future number that you provider for your cellular telephone or other wireless device, by phone or text message using automated telephone dialing system or artificial or prerecorded voice messages, for any legal purpose, including, but not limited to, servicing a loan. You may revoke your consent at any time contacting us at 1-866-936-0602 or P.O. Box 29429, Atlanta, GA 30359.',
  email: 'The email address must belong to the Applicant completing this section. You agree that we may use this Email address to send and respond to messages related to your account, as well as information about our products/services and promotions. You understand that email is not a secure means of communication and agree that we are not responsible for any misdirection, interception, or other disclosure of the contents of an email property addressed to you. As we are unable to guarantee the confidentiality of emails, please do not include private or confidential information. If you would like to opt out, contact us at optout@greenskycredit.com.',
  // grossIncome: 'Income: Monthly salary (before taxes), alimony, child support, investment income, social security, etc. NOTE: Alimony, child support, or separate maintenance payments need not be revealed if you do not wish to have them considered as a basis for repaying your loan. Married Wisconsin Residents: Combine spouse and your income information once.'
};

function Submitting(props) {
  if (props.isSubmitting) {
    return (
      <ReactLoading type="bubbles" color="black" height={'50%'} width={'50%'} />
    );
  }
  return (<span></span>);
}

class PreQualificationForm extends React.Component {
  constructor(props) {
    super(props);

    let slug = this.props.match.params.slug;
    if(!slug) {
      alert('Missing store slug');
      return;
    }

    let params = new URLSearchParams(this.props.location.search);

    this.initialValues = PreQualificationFormInitialStates(params.get('state') || 'default');

    this.initialValues.lender = params.get('lender') || '';
    this.initialValues.response = params.get('state') || '';
    // const store = Stores.fromSlug(slug);
    axios.defaults.params = axios.defaults.params || {};

    this.state = {
      services: [],
      loading: true,
      slug,
      store: {},
      errorSummary: null,
      disclosures: {
        email: false,
        mobilePhone: false,
        homePhone: false,
        grossIncome: false
      }
    };
  }

  componentDidMount() {
    Stores.fromSlug(this.state.slug)
      .then((store) => {
        axios.defaults.params.storeName = store.name;
        this.setState({
          store,
          loading: false
        });
        ServiceLending.lenders(store, this.initialValues);
      });
  }

  toggleDisclosure(which) {
    const newDisclosureState =
      Object.assign({}, this.state.disclosures);
    newDisclosureState[which] = !newDisclosureState[which];
    this.setState({disclosures: newDisclosureState});
  }

  render() {
    return (
      <div id="body">
        {this.state.loading && 
          (<ReactLoading type="bubbles" color="black" height={'15%'} width={'15%'} textAlign="center" />)
        }
        
        { !this.state.loading && this.state.services.length === 0 &&
          <Services onSubmit={(services) => {
            this.setState({
              services
            })
          }} />
        } { !this.state.loading && this.state.services.length > 0 &&
          <div>
          <h1>Pre-Qualification Form</h1>

          <h2>Personal Information</h2>
          <Formik
            initialValues={this.initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting }) => {
              values.storeSlug = this.state.store.slug;
              submitHandler(values, this.state.services, this.state.store, {
                setSubmitting,
                history: this.props.history,
                setErrorSummary: (errorSummary) => {
                  this.setState({ errorSummary });
                }
              });
            }}
          >
            {({ isSubmitting, values, errors, handleBlur, handleChange, touched, setFieldValue }) => (
              <Form>
                <Field type="hidden" name="next"></Field>
                <Field type="hidden" name="response"></Field>
                <div className="rowWrap">
                  <div className="formRow">
                    <div style={{width: '40%'}}>
                      <label htmlFor="firstName">First Name*</label>
                      <Field name="firstName" id="firstName" type="text"
                        className={fieldClasses([], touched.firstName && errors.firstName)} />
                      <div className="error">
                        <ErrorMessage name="firstName" component="span" />
                      </div>
                    </div>
                    <div style={{width: '20%'}}>
                      <label htmlFor="middleInitial">Middle Initial</label>
                      <Field name="middleInitial" id="middleInitial" type="text" maxLength="1"/>
                    </div>
                    <div style={{width: '40%'}}>
                      <label htmlFor="lastName">Last Name*</label>
                      <Field name="lastName" id="lastName" type="text"
                        className={fieldClasses(["fs-block"], touched.lastName && errors.lastName)} />
                      <div className="error">
                        <ErrorMessage name="lastName" component="span" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="rowWrap">
                  <div className="formRow">
                    <div style={{width: '50%'}}>
                      <label htmlFor="streetAddressLine1">Address*</label>
                      <Field name="streetAddressLine1" id="streetAddressLine1" type="text"
                        className={fieldClasses(["fs-block"], touched.streetAddressLine1 && errors.streetAddressLine1)} />
                      <div className="error">
                        <ErrorMessage name="streetAddressLine1" component="span" />
                      </div>
                    </div>
                    <div style={{width: '50%'}}>
                      <label htmlFor="streetAddressLine2">Unit / APT Number</label>
                      <Field name="streetAddressLine2" id="streetAddressLine2" type="text" />
                    </div>
                  </div>
                </div>
                <div className="rowWrap">
                  <div className="formRow">
                    <div style={{width: '49%'}}>
                      <label htmlFor="city">City*</label>
                      <Field name="city" id="city" type="text"
                        className={fieldClasses([], touched.city && errors.city)} />
                      <div className="error">
                        <ErrorMessage name="city" component="span" />
                      </div>
                    </div>
                    <div  style={{width: '20%'}}>
                      <label htmlFor="state">State*</label>

                      <Field name="state" id="state" component="select" placeholder="Choose A State"
                        className={fieldClasses([], touched.state && errors.state)}>
                        {Object.keys(States).map((key) =>
                          (<option key={key} value={key}>{key}</option>)
                        )}
                      </Field>
                      <div className="error">
                        <ErrorMessage name="state" component="span" />
                      </div>
                    </div>
                    <div style={{width: '30%'}}>
                      <label htmlFor="zip">Zip*</label>
                      <Field name="zip" id="zip" type="text" maxLength="5"
                        className={fieldClasses([], touched.zip && errors.zip)} />
                      <div className="error">
                        <ErrorMessage name="zip" component="span" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="rowWrap">
                  <div className="formRow">
                    <BirthdayFields width="64%" values={values} errors={errors} touched={touched} />
                    <div style={{width: "15%"}}>
                      <label htmlFor="ssnArea">Social Sec*</label>
                      <Field name="ssnArea" id="ssnArea" maxLength="3" type="tel"
                        className={fieldClasses(["fs-block"], touched.ssnArea && errors.ssnArea)}
                        onChange={(e) => {
                          if(e.target.value &&
                              e.target.value.match(/\D/)) {
                            return;
                          }
                          handleChange(e);
                        }}/>
                      <div className="error">
                        <ErrorMessage name="ssnArea" component="span" />
                      </div>
                    </div>
                    <div style={{width: "10%"}}>
                      <label>&nbsp;</label>
                      <Field name="ssnGroup" maxLength="2" type="tel"
                        className={fieldClasses(["fs-block"], touched.ssnGroup && errors.ssnGroup)}
                        onChange={(e) => {
                          if(e.target.value &&
                              e.target.value.match(/\D/)) {
                            return;
                          }
                          handleChange(e);
                        }}/>
                      <div className="error">
                        <ErrorMessage name="ssnGroup" component="span" />
                      </div>
                    </div>
                    <div style={{width: "15%"}}>
                      <label>&nbsp;</label>
                      <Field name="ssnSerial" type="tel" maxLength="4"
                        className={fieldClasses([], touched.ssnSerial && errors.ssnSerial)}
                        onChange={(e) => {
                          if(e.target.value &&
                              e.target.value.match(/\D/)) {
                            return;
                          }
                          handleChange(e);
                        }}/>
                      <div className="error">
                        <ErrorMessage name="ssnSerial" component="span" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="rowWrap">
                  <div className="formRow">
                    <div style={{width: "100%"}}>
                      <label htmlFor="email">Email*</label>
                      <Field name="email" id="email" type="text"
                        onFocus={() => { this.toggleDisclosure('email') }}
                        onBlur={(e) => { handleBlur(e); this.toggleDisclosure('email') }}
                        className={fieldClasses([], touched.email && errors.email)}
                      />
                      <div className="error">
                        <ErrorMessage name="email" component="span" />
                      </div>
                      {this.state.disclosures.email && (
                        <div className="fieldDisclosure">
                          {fieldDisclosures.email}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="rowWrap">
                  <div className="formRow">
                    <PhoneFields phoneType="mobile"
                      label="Mobile Phone"
                      toggleDisclosure={this.toggleDisclosure.bind(this)}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      required={true}
                      touched={touched}
                      errors={errors}
                      width='50%'/>
                    <PhoneFields phoneType="home"
                      label="Home Phone"
                      toggleDisclosure={this.toggleDisclosure.bind(this)}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      touched={touched}
                      errors={errors}
                      width='50%'/>
                  </div>
                  {this.state.disclosures.mobilePhone && (
                    <div className="fieldDisclosure">
                      {fieldDisclosures.mobilePhone}
                    </div>
                  )}
                  {this.state.disclosures.homePhone && (
                    <div className="fieldDisclosure">
                      {fieldDisclosures.homePhone}
                    </div>
                  )}
                </div>

                <h2>Employment &amp; Income</h2>
                <div className="rowWrap">
                  <div className="formRow">
                    <div style={{width: '30%'}}>
                      <label htmlFor="employmentStatus">Employment Status*</label>
                      <Field name="employmentStatus" id="employmentStatus" component="select"
                        placeholder="Choose Status"
                        className={fieldClasses([], touched.employmentStatus && errors.employmentStatus)}
                        onChange={(e) => {
                          if(!["Employed", "Self Employed"].includes(e.target.value)) {
                            setFieldValue('employerName', '');
                            setFieldValue('employmentPosition', '');
                            setFieldValue('employerPhoneArea', '');
                            setFieldValue('employerPhonePrefix', '');
                            setFieldValue('employerPhoneLine', '');
                          }
                          if(!["Employed", 
                                "Retired", 
                                "Self Employed", 
                                "Social Security/Disability"].includes(e.target.value)) {
                            setFieldValue('employmentDate', '');
                          }
                          handleChange(e);
                        }}
                      >
                        {EmploymentStatuses.map((key) =>
                          (<option key={key} value={key}>{key}</option>)
                        )}
                      </Field>
                      <div className="error">
                        <ErrorMessage name="employmentStatus" component="span" />
                      </div>
                    </div>
                    <div style={{width: '40%'}}>
                      <label htmlFor="employerName">Employer Name</label>
                      <Field name="employerName" id="employerName" type="text"
                        className={fieldClasses([], touched.employerName && errors.employerName)}
                        disabled={!["Employed", "Self Employed"].includes(values.employmentStatus)}
                      />
                      <div className="error">
                        <ErrorMessage name="employerName" component="span" />
                      </div>
                    </div>
                    <div style={{width: '30%'}}>
                      <label htmlFor="employmentDate">Employment Date (mm/dd/yyyy)</label>
                      <Field name="employmentDate" id="employmentDate" type="text"
                        onChange={(e) => {
                          var val = e.target.value;
                          var newVal = val;
                          if(val.indexOf('/') > -1) {
                            var tokens = val.split('/');
                            tokens.forEach((token, i) => {
                              if(i < (tokens.length - 1) && token && token.length < 2) {
                                tokens[i] = token.padStart(2, '0');
                              }
                            });
                            newVal = tokens.join('/');
                          }
                          setFieldValue('employmentDate', newVal);
                        }}
                        className={fieldClasses([], touched.employmentDate && errors.employmentDate)}
                        disabled={!["Employed", 
                                    "Retired", 
                                    "Self Employed", 
                                    "Social Security/Disability"].includes(values.employmentStatus)}
                      />
                      <div className="error">
                        <ErrorMessage name="employmentDate" component="span" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="rowWrap">
                  <div className="formRow">
                    <div style={{width: '50%'}}>
                      <label htmlFor="employmentPosition">Employment Position</label>
                      <Field name="employmentPosition" id="employmentPosition" type="text"
                        className={fieldClasses([], touched.employmentPosition && errors.employmentPosition)}
                        disabled={!["Employed", "Self Employed"].includes(values.employmentStatus)}
                      />
                      <div className="error">
                        <ErrorMessage name="employmentPosition" component="span" />
                      </div>
                    </div>                  
                    <PhoneFields phoneType="employer"
                      disabled={!["Employed", "Self Employed"].includes(values.employmentStatus)}
                      label="Employer Phone"
                      toggleDisclosure={() => {}}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      touched={touched}
                      errors={errors}
                      width='50%'/>
                  </div>
                </div>

                <div className="rowWrap">
                  <div className="formRow">
                    <div style={{width: '50%'}}>
                      <label htmlFor="rentOrOwn">Rent or Own*</label>
                      <Field name="rentOrOwn" id="rentOrOwn" component="select"
                        placeholder="Choose Status"
                        className={fieldClasses([], touched.rentOrOwn && errors.rentOrOwn)}
                      >
                        {["", "Rent", "Own"].map((key) =>
                          (<option key={key} value={key}>{key}</option>)
                        )}
                      </Field>
                      <div className="error">
                        <ErrorMessage name="rentOrOwn" component="span" />
                      </div>
                    </div>
                    <div style={{width: '50%'}}>
                      <label htmlFor="mortgageLeaseAmount">Mortgage/Lease Amount</label>
                      <Field name="mortgageLeaseAmount" id="mortgageLeaseAmount" type="number"
                        className={fieldClasses([], touched.mortgageLeaseAmount && errors.mortgageLeaseAmount)}
                      />
                      <div className="error">
                        <ErrorMessage name="mortgageLeaseAmount" component="span" />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="rowWrap">
                <div className="formRow">
                    <AnnualIncome toggleDisclosure={this.toggleDisclosure.bind(this)}
                      touched={touched}
                      errors={errors}
                      setFieldValue={setFieldValue}
                      handleBlur={handleBlur}
                    />
                <div style={{width: '50%'}}>
                    <label htmlFor="requestAmount">Requested Amount</label>
                    <Field name="requestAmount"
                      id="requestAmount"
                      type="tel"
                      className={fieldClasses([], touched.requestAmount && errors.requestAmount)}
                      onChange={(event) => {
                        if(!event.target.value) {
                          setFieldValue('requestAmount', '');
                          return;
                        }
                        const val = event.target.value.toString().replace(/\D/, '');
                        setFieldValue('requestAmount', parseInt(val).toLocaleString());
                      }}/>
                    <label>Must be between $1000 and $10000</label>
                    <div className="error">
                      <ErrorMessage name="requestAmount" component="span" />
                    </div>
                  </div>
    
                  </div>
                  {this.state.disclosures.grossIncome && (
                    <p className="fieldDisclosure">
                      {fieldDisclosures.grossIncome}
                    </p>
                  )}
                </div>
                <h2>Legal</h2>
                <div className="formRow">
                  <Field name="agreed" id="agreed" type="checkbox" />
                  <div>
                    <div className="error" style={{position: 'relative'}}>
                      <ErrorMessage name="agreed" component="span" />
                    </div>
                    <label htmlFor="agreed" style={{marginLeft: '5px'}}>YOU AGREE AND UNDERSTAND THAT BY CLICKING ON THE "Accept & Submit" BUTTON IMMEDIATELY FOLLOWING THIS NOTICE YOU HAVE READ, UNDERSTAND, AND ACCEPT THE LaserAway Advantage Disclosures.</label>
                    <div className="legal">
                      <ul>
                        <li>By submitting this pre-qualification form, you are providing written instructions to LaserAway and its Financing Partners (Partners) under the Fair Credit Reporting Act, authorizing LaserAway and its Partners to obtain information from your personal credit profile and other information as is necessary to make a credit decision.  You authorize LaserAway and its Partners to obtain such information to conduct a pre-qualification for credit.  For this pre-qualification form it is acceptable to use all household income in consideration for repaying this loan.  However, alimony, child support or separate maintenance income need not be revealed if you do not wish to have it considered as a basis for repaying this obligation.</li>
                        <li>By checking the box above, you are stating that you have read, understand, and accept <a href="https://www.laseraway.com/privacy/" target="_blank" rel="noopener noreferrer">LaserAway's Privacy Notice</a> and <a href="/teletextpolicy.html" target="_blank" rel="noopener noreferrer">Telephone and Texting Policy</a>.  Additionally, by providing an email address, you consent to receive email communications about your account and authorize LaserAway and its Financing Partners to use email to send communications, offers and updates.  The Financing Partner you choose to move forward with may have their own terms, policies and conditions and you will have the opportunity to review those prior to moving forward with any financing arrangement.  LaserAway is not extending credit through this platform.</li>
                        <li>You further warrant that you have filled this pre-qualification form out for yourself and that all information is true and accurate.</li>
                        <li>When you submit a pre-qualification form through this platform to check your rate and review loan offers from one of our Financing Partners, a soft inquiry will be performed on your credit report, which will not impact your credit score. Soft inquiries can only be seen by you. If you accept a loan offer from one of our Financing Partners and submit it for review, a hard inquiry will be performed, which may impact your credit score and can be viewed by third parties.</li>
                        <li>You understand this is not a credit application.  By continuing, you authorize LaserAway and its Financing Partners to obtain information from consumer reporting agencies to determine if you are prequalified for credit.  You understand that if prequalified you will need to accept the credit offer and complete the application process.</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div style={{margin: '10px 0 200px 0', float: 'right', textAlign: 'right'}}>
                  <button type="submit"
                    disabled={isSubmitting}
                    id="Next"
                    className={isSubmitting ? 'disabled' : ''}>
                    Next
                  </button>
                  { Object.keys(errors).length > 0 && (
                    <div className="error" style={{position: 'relative', textAlign: 'right'}}>Please check your inputs.</div>
                  )}
                  {this.state.errorSummary && (
                    <div className="error" style={{position: 'relative', textAlign: 'right'}}>{this.state.errorSummary}</div>
                  )}
                  <Submitting isSubmitting={isSubmitting} />
                </div>
                <div style={{clear: 'both'}}></div>
              </Form>
            )}
          </Formik>
        </div>
      }
      </div>
    );
  }
};

export default PreQualificationForm;