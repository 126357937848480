import React from 'react';
import { Field, ErrorMessage } from 'formik';

class AllegroTerms extends React.Component {
  render() {
    return (<div>
      <div style={{'textAlign': 'left', 'fontSize': '12px', 'marginBottom': '30px', 'marginTop': '15px'}}>
        Federal law requires that all financial institutions obtain, verify and record identifying information such as your name, date of birth, and address when you open an account. Synchrony Bank will use your name, address, date of birth, and other information for this purpose.
      </div>

      <div className="formRow">
        <Field name="agreed" id="agreed" type="checkbox" />
        <div>
          <div className="error" style={{position: 'relative'}}>
            <ErrorMessage name="agreed" component="span" />
          </div>
          <label htmlFor="agreed" style={{marginLeft: '5px'}}>I grant permission to share my personal information with Synchrony Bank and allow them to pull my credit report in connection with this pre-qualification request.</label>
        </div>
      </div>
    </div>);
  }
}

export default AllegroTerms;

