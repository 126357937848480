const axios = require('axios');

const headers = {
  'Content-Type': 'application/json'
};
const lender = 'Varidi';
const ServiceLending = {
    lender,
    eligible: (services, store, formState) => {
      if(formState.lender) {
        if(formState.lender !== lender) {
          console.log(`form configured for ${formState.lender}, ${lender} is ineligible`);
          return false;
        } else {
          console.log(`form configured for ${formState.lender}, ${lender} is eligible.`);
          return true;
        }
      }
      const isEligible = !!store.varidiMerchantId && !(services.length === 1 && services[0] === 'none')
      console.log(`${lender} is eligible: ${isEligible}`);
      return isEligible;
    },
    wrapResponse: (promise) => {
      return promise.then((result) => {
        const resultWrapper = {
          status: "0",
          message: 'Initialized',
          redirectUrl: null,
          amount: -1,
          originalResult: result,
          lender: 'Varidi'
        };
        if(result.data.IsSuccessful) {
          resultWrapper.status = '1';
          resultWrapper.redirectUrl = encodeURIComponent(`${process.env.REACT_APP_VERIDI_PORTAL_URL_PREFIX}${result.data.RedirectUrlPortion}`);
          resultWrapper.reEntryUrl = resultWrapper.redirectUrl;
          delete resultWrapper.redirectUrl;
          resultWrapper.amount = result.data.GuaranteedAmount;
        }
        return resultWrapper;
      }).catch((e) => {
        return Promise.reject({
          lender,
          error: e
        });
      });
    },
    createApp: (formState, store) => {
      const values = ServiceLending.formStateAdapter(formState, store);
      values.VeridiMerchantId = store.varidiMerchantId;
      return ServiceLending.wrapResponse(ServiceLending.createPaymentPlanContract(values));
    },

    getGuaranteedAmount: (values) => {
      const getGuaranteedAmountApiUrl = 
          `${process.env.REACT_APP_SERVICE_LENDING_URL}/v1/veridi/getGuaranteedAmount`;
      return axios.post(getGuaranteedAmountApiUrl, values, { headers });
    },
    createPaymentPlanContract: (values) => {
      const createPaymentPlanContractApiUrl = 
            `${process.env.REACT_APP_SERVICE_LENDING_URL}/v1/veridi/createPaymentPlanContract`;
        return axios.post(createPaymentPlanContractApiUrl, values, { headers });
    },
    monthDiff(dateFrom, dateTo) {
        return dateTo.getMonth() - dateFrom.getMonth() + 
        (12 * (dateTo.getFullYear() - dateFrom.getFullYear()))
    },
    formStateAdapter: (formState, store) => {
      let today = new Date();
      today.setHours(0);
      today.setMinutes(0);
      today.setSeconds(0);
      today.setMilliseconds(0);
      
      const CountryCode = '1';
      const PhoneNumber = formState.homePhoneArea ? ['homePhoneArea', 'homePhonePrefix', 'homePhoneLine'].reduce((memo, att) => {
        return `${memo}${formState[att].replace(/\D/ig, '')}`;
      }, '') : ['mobilePhoneArea', 'mobilePhonePrefix', 'mobilePhoneLine'].reduce((memo, att) => {
        return `${memo}${formState[att].replace(/\D/ig, '')}`;
      }, '');
      const AccountHolderEmailAddress = formState.email;
      const AccountHolderFullName = `${formState.firstName} ${formState.middleInitial || ''} ${formState.lastName}`;
      const ContractDescription = 'LaserAway Services.';
      const MonthlyIncome = parseInt(parseInt(formState.grossIncome.toString().replace(/\D/g, '')) / 12);
      const ResidenceType = formState.rentOrOwn;
      const CustomerPreviousVisits = '0';
      const IsCurrentlyEmployed = formState.employmentStatus === 'Employed';
      const EmployerName = IsCurrentlyEmployed ? formState.employerName : undefined;
      const MonthsAtCurrentEmployer = IsCurrentlyEmployed ?
        parseInt(ServiceLending.monthDiff(new Date(Date.parse(formState.employmentDate)), today)) : 1;
      const WorkPhone = formState.employerPhoneArea ? ['employerPhoneArea', 'employerPhonePrefix', 'employerPhoneLine'].reduce((memo, att) => {
          return `${memo}${formState[att].replace(/\D/ig, '')}`;
        }, '') : PhoneNumber;
      const ContractAmount = parseFloat(formState.requestAmount.toString().replace(/\D/, ''));
      today = today.toISOString().split('T')[0];
      let AccountHolderDateOfBirth = new Date();
      AccountHolderDateOfBirth.setYear(formState.dobYear);
      AccountHolderDateOfBirth.setMonth(formState.dobMonth);
      AccountHolderDateOfBirth.setDate(formState.dobDay);
      AccountHolderDateOfBirth.setHours(0);
      AccountHolderDateOfBirth.setMinutes(0);
      AccountHolderDateOfBirth.setSeconds(0);
      AccountHolderDateOfBirth.setMilliseconds(0);
      AccountHolderDateOfBirth = AccountHolderDateOfBirth.toISOString().split('T')[0];
      const AccountHolderAddress = {
        Street: formState.streetAddressLine1,
        City: formState.city,
        StateAbbreviation: formState.state,
        Zip: formState.zip,
        CountryCode: "USA"
      };
      const VeridiMerchantId = formState.location_id;
      return {
        VeridiMerchantId, //to get plucked off
        ContractAmount, //to get plucked off
        AccountHolderEmailAddress,
        AccountHolderFullName,
        AccountHolderPhone: {
            CountryCode,
            PhoneNumber 
        },
        ContractDescription,
        ShouldVaridiCollectDownPayment: false,
        ContractStartDate: today,
        ServiceDate: today,
        RequireBackUpPaymentMethod: false,
        HasGuarantee: true,
        HasPatientData: false,
        AccountGuaranteeData: {
            MonthlyIncome,
            CustomerPreviousVisits,
            ResidenceType,
            IsCurrentlyEmployed,
            EmployerName,
            MonthsAtCurrentEmployer
        },
        
        AccountHolderWorkPhone: {
            CountryCode,
            PhoneNumber: WorkPhone
        },
        AccountHolderDateOfBirth,
        AccountHolderAddress
        // "DownPaymentAmount": 1.00,
        // MaxTermAllowed: 1,
        // "PatientInfo": {
        //     "Name": "Test User",
        //     "DateOfBirth": "1980-25-12",
        //     "Phone": {
        //         "CountryCode": "1",
        //         "Extension": "123",
        //         "PhoneNumber": "1234567"
        //     },
        //     "EmailAddress": "test@laseraway.net"
        // },
        // "ExpenseItemList": [],
      };
    }
};

module.exports = ServiceLending;