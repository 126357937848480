const Yup = require('yup');
const moment = require('moment');

const validationShape = {
  firstName: Yup.string()
    .required('Required Field'),
  middleInitial: Yup.string()
    .notRequired()
    .nullable(),
  lastName: Yup.string()
    .required('Required Field'),
  streetAddressLine1: Yup.string()
    .matches(/^(?!p\.o\.?).*/i, 'P.O. boxes not allowed')
    .required('Required Field'),
  streetAddressLine2: Yup.string()
    .notRequired()
    .nullable(),
  city: Yup.string()
    .required('Required Field'),
  state: Yup.string()
    .required('Required Field'),
  zip: Yup.string()
    .required('Required Field')
    .matches(/\d{5}/, '5 digits'),
  dobMonth: Yup.string()
    .required('Required Field'),
  dobDay: Yup.string()
    .required('Required Field'),
  dobYear: Yup.string()
    .required('Required Field'),
  ssnArea: Yup.string()
    .required('Required')
    .matches(/\d{3}/, '3 digits'),
  ssnGroup: Yup.string()
    .required('Req.*')
    .matches(/\d{2}/, '2 digits'),
  ssnSerial: Yup.string()
    .required('Req.*')
    .matches(/\d{4}/, '4 digits'),
  email: Yup.string()
    .required('Required Field')
    .email('Valid email address'),
  mobilePhoneArea: Yup.string()
    .required('Required Field')
    .matches(/\d{3}/, '3 digits'),
  mobilePhonePrefix: Yup.string()
    .required('Required Field')
    .matches(/\d{3}/, '3 digits'),
  mobilePhoneLine: Yup.string()
    .required('Required Field')
    .matches(/\d{4}/, '4 digits'),
  homePhoneArea: Yup.string()
    .notRequired()
    .matches(/\d{3}/, '3 digits')
    .nullable(),
  homePhonePrefix: Yup.string()
    .notRequired()
    .matches(/\d{3}/, '3 digits')
    .nullable(),
  homePhoneLine: Yup.string()
    .notRequired()
    .matches(/\d{4}/, '4 digits')
    .nullable(),
  grossIncome: Yup.string()
    .required('Required Field')
    .test(
      '',
      'Must be >= $15,000',
      value => {
        value = value || '';
        const numVal = parseInt(value.replace(/\D/g, ''));
        return numVal >= 15000;
      }
    ),
  employmentStatus: Yup.string()
    .required('Required Field'),
  employerName: Yup.string()
    .when("employmentStatus", {
      is: (employmentStatus) => 
        (["Employed", "Self Employed"].indexOf(employmentStatus) > -1),
      then: Yup.string().required("Required Field")
    }),
  employmentDate: Yup.string()
    .when("employmentStatus", {
      is: (employmentStatus) => (
        ["Employed", 
          "Retired", 
          "Self Employed", 
          "Social Security/Disability"].indexOf(employmentStatus) > -1
        ),
      then: Yup.string()
              .required("Required Field")
              .test(
                '',
                'Must be valid date as mm/dd/yyyy',
                value => {
                  return value &&
                    value.match(/\d{2}\/\d{2}\/\d{4}/) &&
                    moment(value, 'MM/DD/YYYY').isValid() &&
                    value.length === 10
                }
              )
    }),
  employmentPosition: Yup.string()
    .when("employmentStatus", {
      is: (employmentStatus) => 
        (["Employed", "Self Employed"].indexOf(employmentStatus) > -1),
      then: Yup.string().required("Required Field")
    }),
  employerPhoneArea: Yup.string()
    .when("employmentStatus", {
      is: (employmentStatus) => 
        (["Employed", "Self Employed"].indexOf(employmentStatus) > -1),
      then: Yup.string().required('Required Field').matches(/\d{3}/, '3 digits'),
    }),
  employerPhonePrefix: Yup.string()
    .when("employmentStatus", {
      is: (employmentStatus) => 
        (["Employed", "Self Employed"].indexOf(employmentStatus) > -1),
      then: Yup.string().required('Required Field').matches(/\d{3}/, '3 digits'),
    }),
  employerPhoneLine: Yup.string()
    .when("employmentStatus", {
      is: (employmentStatus) => 
        (["Employed", "Self Employed"].indexOf(employmentStatus) > -1),
      then: Yup.string().required('Required Field').matches(/\d{4}/, '4 digits'),
    }),
  rentOrOwn: Yup.string().required('Required Field'),
  mortgageLeaseAmount: Yup.number().required('Required Field'),
  agreed: Yup.bool().required('Required Field').nullable(),
  requestAmount: Yup.string()
  .required('Required Field')
  .test(
    '',
    'Must be between $1,000 and $10,000',
    (value) => {
      value = value || '';
      const numVal = parseInt(value.replace(/\D/g, ''));
      return numVal > 999 && numVal < 10001
    }
  )
};

module.exports = {
  validationShape,
  validationSchema: Yup.object().shape(validationShape),
  shapeCarryover: (carryOverState) => {
      const formState = Object.assign({}, carryOverState);
      const allowedFields = Object.keys(validationShape);

      Object.keys(formState).forEach((key) => {        
        // if its not allowed, remove it  
        if(allowedFields.indexOf(key) === -1) {
          delete formState[key];
        }
      });
      // if we need it but its not there set it to undefined
      allowedFields.forEach((key) => {
        if(!formState[key]) {
          formState[key] = undefined;
        }
      });
      formState.agreed = null;
      return formState;
  }
};