import React from 'react';
import { Field, ErrorMessage } from 'formik';

class EpfTerms extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      disclosures: props.disclosures
    };
  }
  render() {
    return (<div>
      <div style={{'textAlign': 'left', 'fontSize': '12px', 'marginBottom': '30px', 'marginTop': '15px'}}>
        {this.state.disclosures.map((disclosure) => {
          if(disclosure.Disclosure.HtmlContent) {
            return (<div>
              <h6>{disclosure.Disclosure.Title}</h6>
              <div dangerouslySetInnerHTML={{ __html: disclosure.Disclosure.HtmlContent }}></div>
            </div>)  
          } else {
            return (<div>
              <h6>{disclosure.Disclosure.Title}</h6>
              <div dangerouslySetInnerHTML={{ __html: disclosure.Disclosure.RawContent.replace('\\r\\n', '<br/>') }}></div>
            </div>)  
          }
          
        })}
      </div>

      <div className="formRow">
        <Field name="agreed" id="agreed" type="checkbox" />
        <div>
          <div className="error" style={{position: 'relative'}}>
            <ErrorMessage name="agreed" component="span" />
          </div>
          <label htmlFor="agreed" style={{marginLeft: '5px'}}>I agree to the terms &amp; conditions.</label>
        </div>
      </div>
    </div>);
  }
}

export default EpfTerms;

