import React from 'react';
import { Field, ErrorMessage } from 'formik';
import { fieldClasses } from './Helpers';

const PhoneFields = (props) => {
    const touched = props.touched;
    const errors = props.errors;
    const disabled = props.disabled;
    return (<div style={{width: props.width, flexDirection: 'row', display: 'flex', marginRight: '0'}}>
      <div style={{width: "30%"}}>
        <label
          htmlFor={`${props.phoneType}PhoneArea`}
          style={{position: 'absolute'}}>{props.label}{props.required ? "*" : ""}</label>
        <Field
          style={{marginTop: '19px'}}
          name={`${props.phoneType}PhoneArea`}
          id={`${props.phoneType}PhoneArea`} 
          maxLength="3"
          type="tel"
          disabled={disabled}
          className={fieldClasses(["fs-block"], touched[`${props.phoneType}PhoneArea`] && errors[`${props.phoneType}PhoneArea`])}
          onFocus={() => { props.toggleDisclosure(`${props.phoneType}Phone`) }}
          onBlur={(e) => {
            props.handleBlur(e);
            props.toggleDisclosure(`${props.phoneType}Phone`)
          }}
          onChange={(e) => {
            if(e.target.value &&
                e.target.value.match(/\D/)) {
              return;
            }
            props.handleChange(e);
          }}/>
        <div className="error">
          <ErrorMessage name={`${props.phoneType}PhoneArea`} component="span" />
        </div>
      </div>
      <div style={{width: "30%"}}>
        <label>&nbsp;</label>
        <Field
          name={`${props.phoneType}PhonePrefix`}
          id={`${props.phoneType}PhonePrefix`}
          className={fieldClasses(["fs-block"], touched[`${props.phoneType}PhonePrefix`] && errors[`${props.phoneType}PhonePrefix`])}
          type="tel"
          disabled={disabled}
          maxLength="3"
          onFocus={() => { props.toggleDisclosure(`${props.phoneType}Phone`) }}
          onBlur={(e) => {
            props.handleBlur(e);
            props.toggleDisclosure(`${props.phoneType}Phone`)
          }}
          onChange={(e) => {
            if(e.target.value &&
                e.target.value.match(/\D/)) {
              return;
            }
            props.handleChange(e);
          }}/>
        <div className="error">
          <ErrorMessage name={`${props.phoneType}PhonePrefix`} component="span" />
        </div>
      </div>
      <div style={{width: "40%"}}>
        <label>&nbsp;</label>
        <Field
          name={`${props.phoneType}PhoneLine`}
          id={`${props.phoneType}PhoneLine`}
          className={fieldClasses([], touched[`${props.phoneType}PhoneLine`] && errors[`${props.phoneType}PhoneLine`])}
          type="tel"
          disabled={disabled}
          maxLength="4"
          onFocus={() => { props.toggleDisclosure(`${props.phoneType}Phone`) }}
          onBlur={(e) => {
            props.handleBlur(e);
            props.toggleDisclosure(`${props.phoneType}Phone`)
          }}
          onChange={(e) => {
            if(e.target.value &&
                e.target.value.match(/\D/)) {
              return;
            }
            props.handleChange(e);
          }}/>
        <div className="error">
          <ErrorMessage name={`${props.phoneType}PhoneLine`} component="span" />
        </div>
      </div>
    </div>);
};
export default PhoneFields;