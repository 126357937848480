const axios = require('axios');
const moment = require('moment');

const headers = {
  'Content-Type': 'application/json'
};

const HousingCodes = [
  {
      Text: "Rent",
      Value: 1
  },
  {
      Text: "Own",
      Value: 2
  },
  {
      Text: "Lives With Parents",
      Value: 3
  },
  {
      Text: "Other",
      Value: 4
  }
];

const EmploymentTypes = [
    {
        Text: "Employed",
        Value: 1
    },
    {
        Text: "Retired",
        Value: 2
    },
    {
        Text: "Self Employed",
        Value: 3
    },
    {
        Text: "Homemaker",
        Value: 4
    },
    {
        Text: "Student",
        Value: 5
    },
    {
        Text: "Unemployed",
        Value: 6
    },
    {
      Text: 'Social Security/Disability',
      Value: 6
    }
];

// const DecisionStatuses = [
//     {
//         Text: "None",
//         Value: 0
//     },
//     {
//         Text: "Approved",
//         Value: 1
//     },
//     {
//         Text: "Declined",
//         Value: 2
//     },
//     {
//         Text: "Cancelled",
//         Value: 3
//     }
// ];

const ServiceLending = {
    eligible: (services, store) => {
      return true;
    },
    wrapResponse: (promise, lender) => {
      return promise.then((results) => {
        const { appLenderResponse, submissionResponse } = results;
        const result = submissionResponse;
        const resultWrapper = {
          status: "0",
          message: 'Initialized',
          redirectUrl: null,
          amount: -1,
          originalResult: result,
          lender
        };
        if(result.data.Decision === 1) {
          resultWrapper.status = '1';
          resultWrapper.amount = result.data.ApprovalDetails.MaxLineAmount;
          resultWrapper.redirectUrl = encodeURIComponent(`/laa/authorized?amount=${resultWrapper.amount}&lender=${lender}`);

          const fields = appLenderResponse.data.Actions.Prequalification.Fields;
          const fieldKeys = Object.keys(appLenderResponse.data.Actions.Prequalification.Fields);
          
          const disclosureFieldKeys = fieldKeys.filter((fieldKey) => {
            return fields[fieldKey].Group && fields[fieldKey].Group === 'Disclosures'
          });

          resultWrapper.disclosures = disclosureFieldKeys.reduce((memo, key) => {
            memo.push(fields[key]);
            return memo;
          }, []);
        }
        return resultWrapper;
      }).catch((e) => {
        if(e && e.response && e.response.data) {
          console.log(e.response.data);
        }
        return Promise.reject({
          lender,
          error: e
        });
      });
    },
    createCreditApplication: (values, requestedAmount) => {
        const creditApplicationApiUrl = 
            `${process.env.REACT_APP_SERVICE_LENDING_URL}/v1/epf/applications`;
        return axios.post(creditApplicationApiUrl, values, { headers })
          .then((applicationResponse) => {
            const applicationId = applicationResponse.data.ApplicationId;
            const appLenderUrl = 
              `${process.env.REACT_APP_SERVICE_LENDING_URL}/v1/epf/applications/${applicationId}/lenders/15`;
            return axios.post(appLenderUrl, {}, { headers })
              .then((appLenderResponse) => {
                const submissionId = appLenderResponse.data.SubmissionId;
                const allySubmissionApiUrl = 
                  `${process.env.REACT_APP_SERVICE_LENDING_URL}/v1/epf/submissions/${submissionId}/Prequalification`;
                const prequalArgs = {};
                Object.keys(appLenderResponse.data.Actions.Prequalification.Fields).forEach((key) => {
                  if(appLenderResponse.data.Actions.Prequalification.Fields[key]['Group'].toLowerCase() === 'disclosures'){
                    prequalArgs[key] = true;
                  }
                });
                prequalArgs.AmountRequested = requestedAmount;
                prequalArgs.EstimatedServiceDate = new Date().toISOString();
                return axios.post(allySubmissionApiUrl, prequalArgs,
                    { headers }).then((submissionResponse) => {
                  return Promise.resolve({
                    appLenderResponse,
                    submissionResponse
                  });
                });
              }).catch((e) => {
                return Promise.reject(e);
              });
          }).catch((e) => {
            return Promise.reject(e);
          });
    },
    formStateAdapter: (formState, store) => {
      // const fieldMapping = {};
      const adapted = Object.assign({}, formState);
      const now = moment();
      adapted.dob = `${adapted.dobMonth.toString().padStart(2, '0')}/${adapted.dobDay.toString().padStart(2, '0')}/${adapted.dobYear}`;
      adapted.ssn = `${adapted.ssnArea}-${adapted.ssnGroup}-${adapted.ssnSerial}`;
      const ProviderId = store.epfId;
      if(!ProviderId) {
        throw new Error(`missing provider id for ${adapted.storeSlug}`);
      }
      const mobilePhone = {
        number: `(${adapted.mobilePhoneArea})${adapted.mobilePhonePrefix}-${adapted.mobilePhoneLine}`,
        type: 'mobile'
      };
      
      const homePhone = {
        number: `(${adapted.homePhoneArea})${adapted.homePhonePrefix}-${adapted.homePhoneLine}`,
        type: 'home'
      };
      
      if(!homePhone.number.match(/\(\d{3}\)\d{3}-\d{4}/ig)) {
        homePhone.number = mobilePhone.number;
      }

      const employerPhone = {
        number: `(${adapted.employerPhoneArea})${adapted.employerPhonePrefix}-${adapted.employerPhoneLine}`,
        type: 'employer'
      }

      adapted.mailingAddress = {
        address1: formState.streetAddressLine1,
        address2: formState.streetAddressLine2,
        city: formState.city,
        state: formState.state,
        zip: formState.zip 
      };

      adapted.physicalAddress = adapted.mailingAddress;
      adapted.monthlyIncome = parseInt(parseInt(adapted.grossIncome.toString().replace(/\D/g, '')) / 12);
      let monthsEmployed = 1;
      let yearsEmployed = 1;
      if(adapted.employmentDate){
        let totalMonthsEmployed = now.diff(moment(adapted.employmentDate, 'MM-DD-YYYY'), 'months');
        if(totalMonthsEmployed > 0){
          monthsEmployed = totalMonthsEmployed % 12;
          yearsEmployed = Math.round(totalMonthsEmployed / 12);
        }
      }
      
      let HousingCode = HousingCodes.filter((code) => {
        return code.Text === adapted.rentOrOwn;
      })[0];

      let EmploymentType = EmploymentTypes.filter((code) => {
        return code.Text === adapted.employmentStatus;
      })[0];

      let app = {
        Applicant: {
          Contact: {
            FirstName: adapted.firstName,
            MiddleInitial: adapted.middleInitial,
            LastName: adapted.lastName,
            Email: adapted.email,
            CellPhone: mobilePhone.number,
            HomePhone: homePhone.number
          },
          Employment: {
            EmployerName: adapted.employerName || EmploymentType.Text,
            EmploymentType: EmploymentType ? EmploymentType.Value : 6,
            JobTitle: adapted.employmentPosition || EmploymentType.Text,
            MonthlyIncome: adapted.monthlyIncome || 0,
            MonthsEmployed: monthsEmployed || 1,
            YearsEmployed: yearsEmployed || 1,
            WorkPhone: employerPhone.number || ''
          },
          PersonalIdentifiableInformation: {
            SSN: adapted.ssn,
            DateOfBirth: moment(adapted.dob, 'MM-DD-YYYY').utcOffset(0, true).toISOString()
          },
          Addresses: {
            PhysicalAddress: {
              MonthsAtAddress: 1,
              YearsAtAddress: 1,
              HousingCode: HousingCode ? HousingCode.Value : 4,
              RentMortgageAmount: adapted.mortgageLeaseAmount,
              Address1: adapted.mailingAddress.address1,
              Address2: adapted.mailingAddress.address2,
              City: adapted.mailingAddress.city,
              State: adapted.mailingAddress.state,
              Zip: adapted.mailingAddress.zip
            },
            MailingAddress: {
              Address1: adapted.mailingAddress.address1,
              Address2: adapted.mailingAddress.address2,
              City: adapted.mailingAddress.city,
              State: adapted.mailingAddress.state,
              Zip: adapted.mailingAddress.zip
            },
            SameMailingAddress: true
          },
        },
        ProviderId,
        ProviderRep: 'LAA Rep',
        ExternalReferenceId: '',
        IsWebApplication: true,
        ResponseUri: ''
      };
      if(!app.Applicant.Contact.MiddleInitial) {
        delete app.Applicant.Contact.MiddleInitial;
      }
      return app;
    }
};

module.exports = ServiceLending;