import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router'

import { Link } from "react-router-dom";
import Stores from './Stores';
class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            store: {}
        }
    }

    async componentDidMount() {
        const storeSlugMatch =  this.props.location.pathname.match(/\/app\/([\w|-]+)\/?/);
        if(storeSlugMatch) {
            const store = await Stores.fromSlug(storeSlugMatch[1]);
            this.setState({
                store
            })
        }
    }

    static propTypes = {
        location: PropTypes.object.isRequired,
    }

    render () {
        return (<div className="header">
            <div>
                <h2>{this.state.store.name}</h2>
                <h1>
                    <Link to="/"><img alt="LaserAway" src="https://res.cloudinary.com/laseraway-com/image/upload/c_scale,w_200/v1510125016/cro/icon-logo-full.png"/></Link>
                </h1>
                
            </div>
        </div>);
    }
}

export default withRouter(Header);