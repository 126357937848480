// reqiure Store from '../../Stores';
const axios = require('axios');
const { PollUntil } = require('poll-until-promise');

const headers = {
  'Content-Type': 'application/json'
};

const lender = 'CareCredit';

const ServiceLending = {
    lender,
    formStateAdapter: (formState, store) => {
      // console.log('formstate', formState);
      //form mocking...
      if(formState.response) {
        //headers.response = formState.response;
      }
      const adapted = {
        merchantInfo: {
          storeNumber: "0000009898",
          operator: "POS",
          registerNumber: "REGISTER",
          languageCode: "ENU",
          clientId: store.careCreditClientId,
          deviceType: "R",
          country: "US",
          merchantNumber: store.careCreditMerchantId,
          origination: "B",
          productGroupCodeInfo: {
            productCode: "SB",
            groupCode: ""
          },
          "additionalInfo": {
            "businessCodes": [
              ""
            ],
            "processorCodes": [
              ""
            ],
            "departmentCodes": [
              ""
            ]
          }
        },
        "applicantInfo": {
          "cipher.firstName": formState.firstName,
          "cipher.lastName": formState.lastName,
          "address": {
            "cipher.addressLine1": formState.streetAddressLine1,
            "cipher.city": formState.city,
            "cipher.state": formState.state,
            "cipher.zipCode": formState.zip,
          },
          "contact": [
            {
              "contactType": "HOME",
              "contactNumber": `${formState.homePhoneArea}${formState.homePhonePrefix}${formState.homePhoneLine}`,
              "contactExtension": ""
            }
          ],
          "membership": "",
          "alternativeMembership": ""
          },
          "promotionInfo": {
            "acquisitionOfferId": ""
          },
          "checkAccountExistence": true
      };
      if(formState.streetAddressLine2) {
        adapted.applicantInfo.address["cipher.addressLine2"] = formState.streetAddressLine2;
      }
      if(formState.middleInitial) {
        adapted.applicantInfo["cipher.middleInitial"] = formState.middleInitial; 
      }
      delete adapted.response;
      // console.log('adapted',adapted);
      return adapted;
    },
    createApp: (formState, services, store) => {
      const values = ServiceLending.formStateAdapter(formState, store);
      const resultWrapper = {
        status: '0',
        message: 'Initialized',
        redirectUrl: null,
        amount: -1,
        originalResult: {},
        lender
      };
      return ServiceLending.createCreditApplication(values)
        .then((result) => {
          // console.log('result!', result);
          const resultClone = JSON.parse(JSON.stringify(result));
          resultWrapper.originalResult = resultClone;
          switch(result.status) {
            case 200:
              switch(result.data.decisionMessage) {
                case 'APPROVED':
                  resultWrapper.status = '1';
                  resultWrapper.message = 'Approved';
                  resultWrapper.amount = result.data.plccOfferInfo.creditLine;
                  resultWrapper.redirectUrl = 
                    resultWrapper.reEntryUrl =
                    encodeURIComponent(result.headers.location + '?sitecode=BCPUWSA901&dtc=PV91');
                  return Promise.resolve(resultWrapper);
                case 'DECLINED':
                  resultWrapper.message = 'Declined';
                  return Promise.resolve(resultWrapper);
                default:
                  return Promise.resolve(resultWrapper);
              }
              break;
            case 202:
              let pollUntilPromise = new PollUntil({
                stopOnFailure: true
              });
              return pollUntilPromise
                .stopAfter(60 * 1000)    // Stop trying after 60 seconds
                .tryEvery(1000)          // Tries every 1s (from the last failure)
                .execute(() => {
                  return new Promise((resolve, reject) => {
                    return ServiceLending.fetchOfferStatus(result.headers.location)
                      .then((offerStatusResult) => {
                        if(offerStatusResult.status === 202) {
                          return resolve(false);
                        } else {
                          switch(offerStatusResult.data.decisionMessage) {
                            case 'APPROVED':
                              resultWrapper.message = 'Approved';
                              resultWrapper.status = '1';
                              resultWrapper.amount = offerStatusResult.data.plccOfferInfo.creditLine;
                              resultWrapper.redirectUrl =
                                resultWrapper.reEntryUrl =
                                encodeURIComponent(offerStatusResult.headers.location + '?sitecode=BCPUWSA901&dtc=PV91');
                              return resolve(resultWrapper);
                            case 'DECLINED':
                              resultWrapper.message = 'Declined';
                              return resolve(resultWrapper);
                            default:
                              return reject(`Unknown state status ${offerStatusResult.data}`);
                          }
                        }
                      });
                  });
                });
              break;
            default:
              break;
            }
        }).catch((e) => {
          if(e.response && e.response.status === 409) {
            resultWrapper.status = '1';
            resultWrapper.message = 'Approved';
            resultWrapper.accountFound = '1';
            resultWrapper.amount = e.response.data.details.accountInfo.availableBalance;
            resultWrapper.redirectUrl =
              resultWrapper.reEntryUrl =
              encodeURIComponent(e.response.headers.location + '?sitecode=BCPUWSA901&dtc=PV91');
            return Promise.resolve(resultWrapper);
          }
          return Promise.reject(e);
        });
    },
    createCreditApplication: (values) => {
        const creditApplicationApiUrl = 
            `${process.env.REACT_APP_SERVICE_LENDING_URL}/v1/care-credit/offers`;
        return axios.post(creditApplicationApiUrl, values, { headers });
    },
    fetchOfferStatus: (url) => {
      return axios.get(url);
    },
    fetchPurl: (values) => {
      const patientDataUrl = 
            `${process.env.REACT_APP_SERVICE_LENDING_URL}/v1/care-credit/patient-data`;
        return axios.post(patientDataUrl, values, { headers });
    },
    eligible: (services, store, formState) => {
      if(formState.lender) {
        if(formState.lender !== lender) {
          console.log(`form configured for ${formState.lender}, ${lender} is ineligible`);
          return false;
        } else {
          console.log(`form configured for ${formState.lender}, ${lender} is eligible.`);
          return true;
        }
      }
      console.log(`${lender} is eligible: ${!!store.careCreditMerchantId}`);
      return !!store.careCreditMerchantId;
    }
};

module.exports = ServiceLending;