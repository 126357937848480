import React from 'react';
import UGATerms from './legal/uga';
import RevolverTerms from './legal/revolver';
import AllegroTerms from './legal/allegro';
import EpfTerms from './legal/epf';
import Varidi from './legal/varidi';
import { Formik, Form } from 'formik';
const Yup = require('yup');

const validationSchema = {
    agreed: Yup.bool().required('Required Field')
};

class Authorized extends React.Component {
    constructor(props) {
        super(props);
        const params = new URLSearchParams(props.location.search);
        const amount = params.get('amount');
        const lender = params.get('lender');
        const lenderDisplay = lender.toLowerCase() === 'allegro' ? 'Synchrony Bank' : lender;
        const redirect = params.get('redirect');
        const reentry = lender.toLowerCase() === 'greensky';
        const agreed = lender.toLowerCase() === 'varidi';
        let disclosures = [];
        if(params.get('disclosures')){
            disclosures = JSON.parse(atob(decodeURIComponent(params.get('disclosures'))));
        }
        
        this.initialValues = {
            agreed
        };
        this.state = {
            services: [],
            amount,
            lender,
            redirect,
            lenderDisplay,
            reentry,
            agreed,
            disclosures
        };
    };
    render(){
        
        let terms = (<Varidi />);

        switch(this.state.lender.toLowerCase()) {
            case 'greensky':
                terms = (<RevolverTerms />);
                break;
            case 'uga':
                terms = (<UGATerms />);
                break;
            case 'allegro':
                terms = (<AllegroTerms />);
                break;
            case 'ally':
                terms = (<EpfTerms disclosures={this.state.disclosures} />);
                break;
            default:
                break;
        }
        

        return (
            <div id="body" className="status">
                <h1>Congratulations!</h1>
                <h2>You're Pre-qualified For Up To ${this.state.amount} by {this.state.lenderDisplay}</h2>
                {this.state.agreed &&
                    <h2 style={{fontSize: '18px', marginBottom: '12px'}}>Please connect with your LaserAway sales rep to complete your application.</h2>
                }
                {!this.state.agreed &&
                    <div>
                        <h2 style={{fontSize: '16px', marginBottom: '12px'}}>Agree &amp; Click Next to Apply for an Account</h2>
                        {this.state.reentry &&
                            <p style={{fontSize: '12px'}}>You will be redirected to the {this.state.lender} site to complete your application.</p>
                        }
                    
                        <Formik
                            initialValues={this.initialValues}
                            validationSchema={validationSchema}
                            onSubmit={(values, { setSubmitting }) => {
                                if(this.state.reentry) {
                                    window.open(decodeURIComponent(this.state.redirect), '_blank');    
                                } else {
                                    this.setState({
                                        agreed: true
                                    });
                                }
                              }}
                        >
                            {({ isSubmitting, values, errors, handleBlur, handleChange, touched, setFieldValue }) => (
                                <Form>
                                    {terms}
                                    <button type="submit"
                                        className={values.agreed ? '' : 'disabled'}
                                        disabled={!values.agreed}
                                        style={{marginTop: '20px', float: 'none'}}>
                                        Next
                                    </button>
                                </Form>
                            )}
                        </Formik>
                    </div>
                }
            </div>
        );    
    }
};
export default Authorized;
