import React from 'react';
import { Field, ErrorMessage } from 'formik';

class UGATerms extends React.Component {
  render() {
    return (<div className="formRow">
	    <Field name="agreed" id="agreed" type="checkbox" />
	    <div>
	      <div className="error" style={{position: 'relative'}}>
	        <ErrorMessage name="agreed" component="span" />
	      </div>
	      <label htmlFor="agreed" style={{marginLeft: '5px'}}>YOU AGREE AND UNDERSTAND THAT BY CLICKING ON THE "Accept & Submit" BUTTON IMMEDIATELY FOLLOWING THIS NOTICE YOU HAVE READ, UNDERSTAND, AND ACCEPT UNIVERSAL ACCOUNT SERVICING, LLC'S <a href="https://ugafinance.com/terms-and-conditions/" rel="noopener noreferrer" target="_blank">TERMS OF USE</a>, AND UNIVERSAL ACCOUNT SERVICING, LLC'S <a href="https://ugafinance.com/privacy-policy/"  rel="noopener noreferrer" target="_blank">PRIVACY NOTICE</a> AND UNIVERSAL ACCOUNT SERVICING, LLC'S <a href="https://ugafinance.com/esign-consent/" rel="noopener noreferrer" target="_blank">CONSENT TO ELECTRONIC COMMUNICATIONS</a>.</label>
	      <div className="legal">
	        <p><b>Please read the following important notices, certifications and authorizations before submitting an application. Please retain a copy of these for your records.</b></p>
	        <p><b>By submitting this application, I (we) hereby acknowledge and recognize this is an application for credit to purchase a product or service from a retail seller particpating in the UGA Finance Program (the "Program"). I (we) are asking for credit through the UGA Finance Program administered by Universal Account Servicing, LLC ("UAS") that may be extended by a participant of the UGA Finance Program, which may be a retail seller for the product/service or other funding participant (either shall be referred to herein as "Participant"). I (we) agree that:</b></p>
	        <ul>
	        <li>By providing the information in this application to UAS, I authorize UAS itself, on behalf of any Participant, to (1) Obtain my credit report for any legal purpose in connection with this credit application, including any account update, extension of credit, to investigate account disputes, review or collection of my account; (2) Notify a Participant of our credit decision, including my credit score, and if approved, the credit decision and account information, including, but not limited to, my approved credit limit, the repayment period for my account, my approved APR, and account number; (3) I also agree to provide additional financial information upon request; (4) You may contact a Participant to verify the type of procedure(s) and/or service(s) and/or product(s), procedure and/or service date, deposit amount, procedure and/or service and/or product amount, and remit payment upon approval or after approval for verifcation purposes.</li>
	        <li>By submitting this application for credit, I certify the following: (1) all of the statements (whether verbally or written) communicated to UAS, or any Participant, are true and correct, and are made for the purposes of obtaining credit; and, (2) if more than one applicant is applying, each applicant intends to apply for joint credit and none is a co-signer (a “co-signer” is a person who agrees to be liable for the debt of another person without receiving the benefit of the account, whereas a “joint applicant” is a person who intends to apply for joint credit and will receive the benefit of the account; although we allow joint applicants to apply for joint credit, we do not allow borrowers to use “co-signers”). In addition, I expressly authorize the shared disclosure of account related information, including but not limited to, credit decision, proposed terms, and personal credit scores to any joint applicant, which will have the same effect as personal delivery to you.</li>
	        <li>I consent to UAS, and any Participant of the Program, contacting me about my account, including through text messages, automatic telephone dialing systems and/or artificial or prerecorded voice calls for informational, servicing or collection related communications, as provided in the Address/Phone Change and Consent To Electronic Communications provisions of the agreement ("Agreement"). I also agree to update my contact information.</li>
	        <li>I have received, read and agree to the credit terms and other disclosures in this application, and I understand that if my application is approved, the Agreement will be available to me and will govern my account. Among other things, the Agreement: <b>(1) includes a resolving a dispute with arbitration provision that limits my right unless: (a) I reject the provision by following the provision's instructions or (b) I am covered by the Notice for Active Duty Member and their Dependents set forth in the Agreement;</b>   and (2) Makes each applicant responsible for paying the entire amount of the credit extended.</li>
	        </ul>

	        <p><b>THE FOLLOWING IS IMPORTANT INFORMATION ABOUT PROCEDURES FOR OPENING A NEW ACCOUNT: To help the government fight the funding of terrorism and money laundering activities, Federal law requires all financial institutions to obtain, verify, and record information that identifies each person who opens a new account. What this means for you: When you open an account, we will ask for your name, address, date of birth, and other information that will allow us to identify you. We may also ask to see your driver’s license or other identifying documents. In some instances, we may user outside sources to confirm the information. The information you provide is protected by our Privacy Policy and the federal law.</b></p>


	         
	        <p><b>NOTICES:</b>
	        <br/>
	        APR’s will vary depending upon credit ratings and/or payment terms that are approved. Credit approvals are valid for a limited time only.
	        <br/>
	        <b>California Residents:</b> If you are married, you may apply for a separate account.
	        <br/>
	        <b>Ohio Residents:</b> The Ohio laws against discrimination require that all creditors make credit equally available to all credit worthy customers, and that credit reporting agencies maintain separate credit histories on each individual upon request. The Ohio civil rights commission administers compliance with this law.
	        <br/>
	        <b>New York, Rhode Island and Vermont Residents:</b> A consumer credit report may be requested in connection with this application or in connection with updates, renewals or extensions of credit granted as a result of this application. Upon your request, you will be informed whether or not such a report was requested and, if so, the name and address of the agency that furnished the report.
	        <br/>
	        <b>Married Wisconsin Residents:</b> If you are applying for individual credit or joint credit with someone other than your spouse, and your spouse also lives in Wisconsin, combine your financial information with your spouse's financial information. No provision of any marital property agreement, unilateral statement under Section 766.59 of the Wisconsin statutes or court order under section 766.70 adversely affects the interest of the lender, unless the lender, prior to the time credit is granted, is furnished a copy of the agreement, statement of decree or has actual knowledge of the adverse provision when the obligation to the lender is incurred. If married, you understand that your lender must inform your spouse if a credit account is opened for you.
	        <br/>
	        <b>I (we) hereby acknowledge that I (we) am over the age of eighteen (18) years, and that all the information set forth in this application is true, accurate and complete full and complete disclosure thereof. I (we) agree that I (we) have read and agree to the above provisions. Selecting "Accept & Submit" or by signing constitutes My (our) electronic signature.</b></p>
	      </div>
	    </div>
	  </div>);
  }
}

export default UGATerms;