import React, { Component } from 'react';
import Stores from './Stores';
import ReactLoading from 'react-loading';

class StoreList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            stores: [],
            loading: false
        }
    }

    componentDidMount() {
        this.setState({loading: true});
        Stores.stores().then((stores) => {
            this.setState({
                stores,
                loading: false
            })
        });
    }

    render() {
        return (<div id="body">
            <h2>Please Select Your Store</h2>
            {this.state.loading &&
                (<ReactLoading type="bubbles" color="black" height={'15%'} width={'15%'} textAlign="center" />)
            }
            {!this.state.loading &&
            (<ul className="storeList">
            {this.state.stores.filter((store) => {
                return !store.name.startsWith("UAT");
            }).sort((store1, store2) => {
                return store1.name > store2.name ? 1 : -1;
            }).map((store) => {
                return (<li key={store.slug}><a href={`/app/${store.slug}`}>{store.name}</a></li>);
            })}
            </ul>)
        }
        </div>);
    }
}

export default StoreList;
