import UGAService from '../../uga/services/ServiceLending';
import VaridiService from '../../varidi/services/ServiceLending';
import AllyService from '../../epf/services/ally/ServiceLending';
import NotificationsService from './ServiceNotifications';
const axios = require('axios');
const Stores = require('../../Stores');

const defaultLooks = [
  [AllyService], 
  [UGAService], 
  [VaridiService],
];

const ServiceLending = {
    lenders: (store, formState = {}) => {
      let storeLenders = [];
      for(let i = 0; i < defaultLooks.length; i++) {
        storeLenders = storeLenders.concat(defaultLooks[i].filter((service) => {
          return service.eligible(['hair'], store, formState);
        }).map((service) => {
          return service.lender;
        }));
      }
      console.log(`lenders for ${store.slug}`, storeLenders);
    },
    // returns the first offer >= requestedAmount, if requestedAmount is provided
    // otherwise returns the offer with the highest amount
    bestOffer: (offers, requestedAmount) => {
      const approvedOffers = offers.filter((offer) => { return offer.status === '1' });
      if(requestedAmount) {
        return approvedOffers.filter((offer) => { 
          return offer.amount >= requestedAmount; 
        })[0];
      } else {
        return approvedOffers.sort((offer1, offer2) => {
          return offer2.amount - offer1.amount;
        })[0];
      }
    },
    createCreditApplication: async (formState, services, store) => {
      let offer;
      let allResults = [];
      const requestAmount = parseInt(formState.requestAmount.toString().replace(/\D/g, ''));
      const app = {
        firstName: formState.firstName,
        lastName: formState.lastName,
        requestAmount,
        fsSessionUrl: axios.defaults.params.fsSessionUrl,
        storeName: store.slug,
        sessionId: axios.defaults.params.sessionId,
      };

      Object.keys(formState).forEach((key) => {
        if( typeof(formState[key]) === 'string' ) {
          formState[key] = formState[key].trim();
        }
      });

      for(let i = 0; i < defaultLooks.length; i++) {
        //an array of services
        const servicePromises = defaultLooks[i].filter((service) => {
          return service.eligible(services, store, formState);
        }).map((service) => {
          return service.createApp(formState, store, services);
        });
        const results = await Promise.all(servicePromises.map(p => p.catch(e => {
          let message = `${e.lender} API Error`;
          //scrub the sensitive data before posting to rollbar
          try{ delete e.error.config.data; }
          catch(deleteError) {}
          window.onerror(message, window.location.href, 0, 0, JSON.stringify(e));
          if(e.response && e.response.data){
            console.log(e.response.data);
          }
          return e;
        })));
        

        allResults = allResults.concat(results);
        const localOffer = ServiceLending.bestOffer(results, requestAmount);
        
        console.log(`best local offer above ${requestAmount}`, localOffer);
        if(localOffer) {
          offer = localOffer;
          break;
        }
      }
      
      if(!offer) {
        console.log(`all looks complete and no qualifying offers at or above ${requestAmount}`);
        offer = ServiceLending.bestOffer(allResults); 
      }      
      console.log('best offer', offer);

      //send notifications
      for(let j = 0; j < allResults.length; j++) {
        console.log(allResults[j]);
        const response = Object.assign({}, allResults[j], {status: allResults[j].status === '1' ? 'Authorized' : 'Declined'});
        delete response.originalResult;
        delete response.redirectUrl;
        delete response.disclosures;
        if(response.amount === 0 || response.amount){
          response.amount = parseInt(response.amount.toString());
          response.offerPresented = offer && offer.lender === response.lender;
          NotificationsService.create({
            app,
            response
          });  
        }
      }
      return offer;
    }
};

export default ServiceLending;