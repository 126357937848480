import React from 'react';
import { Field, ErrorMessage } from 'formik';

class GreenskyRevolverTerms extends React.Component {
  render() {
    return (
      <div className="formRow">
        <Field name="agreed" id="agreed" type="checkbox" />
        <div>
          <div className="error" style={{position: 'relative'}}>
              <ErrorMessage name="agreed" component="span" />
          </div>
          <label htmlFor="agreed">You agree and understand that by clicking on the "Next" button immediately following this notice:</label>
          <div className="legal">
            <ul className="prequalifyAgreementCopy">
              <li>
                You are providing ‘written instructions’ to the GreenSky<sup>®</sup> Program under the Fair Credit Reporting Act authorizing the GreenSky<sup>®</sup> Program to obtain information from your personal credit profile or other
                information from Experian. You authorize the GreenSky<sup>®</sup> Program to obtain such information solely to conduct a pre-qualification for credit.
                GreenSky<sup>®</sup> may share the Prequalification decision with your Healthcare Provider.
              </li>
              <li>You have read, understand, and accept GreenSky's&nbsp; 
                <a href="https://www.greensky.com/terms/website_terms_of_use.pdf" target="_blank" rel="noopener noreferrer">
                  <span>Terms of Use</span>
                </a>, and GreenSky's&nbsp;
                <a href="https://www.greensky.com/privacy-policy/" target="_blank" rel="noopener noreferrer">
                  <span>Privacy Notice</span>
                </a> and GreenSky's&nbsp;
                <a href="https://www.greenskycredit.com/information" target="_blank" rel="noopener noreferrer">
                  <span>Consent to Electronic Records and Communication</span>
                </a>.
              </li>
              <li>
                When you check your rate and review loan offers, a soft inquiry will be performed on your credit report, which won’t impact your credit score. Soft inquiries can only be seen by you. When you accept a loan offer and submit it for
                review, a hard inquiry will be performed, which may impact your credit score and can be viewed by third parties.</li>
              <li>
                This prequalification service is provided by the GreenSky Patient Solutions<sup>®</sup>  Program to you and not on behalf of LaserAway. The GreenSky Patient Solutions<sup>®</sup> Program will protect the confidentiality of your information in accordance with its
                Privacy Policies but it is not required to comply with the Health Insurance Portability and Accountability Act. LaserAway will not condition your treatment and/or services on whether you submit this prequalification application.
                You may revoke authorization by notifying LaserAway and the GreenSky Patient Solutions<sup>®</sup> Program in writing; however, any actions already taken in reliance on this authorization cannot be reversed, and your revocation will not affect those actions.
              </li>
          </ul>
        </div>
      </div>
    </div>);
  }
}

export default GreenskyRevolverTerms;
